.cp-news-article {
	&-content {
		margin: 3% 7.3% 80px;
		max-width: 1612px;

		@include mq($from: desktop-standard-wide) {
			margin: auto;
			margin-bottom: 80px;
			margin-top: 3%;
		}

		@include mq($until: tablet) {
			margin: 5% 7.3% 50px;
		}
	}

	&-header {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		@include mq($until: tablet) {
			row-gap: 20px;

			.bi.bi-dot {
				display: none;
			}
		}
	}

	&-subheading {
		display: flex;
		flex-direction: row;
		column-gap: 10px;
		justify-content: flex-start;

		@include mq($until: tablet) {
			flex-direction: column;
			align-items: flex-start;
			row-gap: 4px;
		}
	}

	&-text {
		padding: 15px 0;

		a, p>a {
		    display: inline-block;
			font-size: inherit;
			font-weight: inherit;
			font-family: inherit;
		}
	}

	&-image {
		-webkit-box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.23);
		-moz-box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.23);
		box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.23);
		width: 100%;
		height: 100%;

		&-container {
			padding: 25px 0;
			width: 80%;
			margin: auto;

		}
	}

	&-video {
		-webkit-box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.23);
		-moz-box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.23);
		box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.23);
		width: 100%;
	}

	&-fifty-fifty {
		padding: 15px 0;
		&-row {
			display: flex;
			flex-direction: row;
			justify-content: center;
			column-gap: 85px;
			align-items: stretch;
			padding: 25px 0;

			.cp-news-article-text {
				a, p>a {
					display: inline-block;
					font-size: inherit;
					font-family: inherit;
					font-weight: inherit;
				}

				width: 50%;
				padding: 0;
			}

			.cp-news-article-image-container {
				width: 50%;
				padding: 0;
			}

			@include mq($until: desktop) {
				flex-direction: column;
				row-gap: 35px;

				.cp-news-article-text {
					width: 100%;
				}

				.cp-news-article-image-container {
					width: 100%;
				}
			}
		}
	}

	&-back {
		padding: 10px 0px;

		@include mq($until: tablet) {
			padding-bottom: 35px;
		}

		a {
			column-gap: 5px;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
		}
	}

	&-navigation {
		background: $color-orange;

		&-container {
			cursor: pointer;
			&:hover {
				.cp-news-article-navigation-title {
					color: $color-white;
				}
			}
		}

		&-outer-container {
			display: flex;
			justify-content: space-between;
			padding: 25px 7%;
			max-width: 1612px;
			margin: auto;

			@include mq($from: desktop-standard-wide) {
				padding: 25px 0;
			}

			@include mq($until: tablet) {
				flex-direction: column;
				align-items: stretch;
				padding: 7%;
				row-gap: 25px;
			}
		}

		&-previous {
			.cp-news-article-navigation-container {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				@include mq($until: tablet) {
					row-gap: 5px;
				}
			}
		}

		&-next {
			.cp-news-article-navigation-container {
				display: flex;
				flex-direction: column;
				align-items: flex-end;

				@include mq($until: tablet) {
					row-gap: 5px;
				}
			}
		}

		&-title {
			color: $color-dark-grey;
			text-decoration: none !important;
			pointer-events: none;
		}

		&-subtitle {
			color: $color-white;
			pointer-events: none;
		}

		&-title.next {
			text-align: right;
		}
	}

	&-hero {
		height: 80px;
		padding: 0;
		width: 100%;
		background-color: $color-orange;
	}

	&-title {
		text-align: left;
	}
}

.cp-news-hub {
	padding: 120px 124px 85px 123px;
	max-width: 1612px;
	margin: auto;

	@include mq($until: tablet) {
		padding: 70px 20px 60px 20px;
	}

	.validation {
		padding-bottom: 10px;
		margin: auto;
		max-width: 1612px;
		font-size: 1rem;

		@include mq($from: desktop-ultra-wide) {
			width: 90%;
		}
	}

	&-top-container {
		background: $color-orange;
		color: $color-white;
		margin: auto;
		width: 100%;

		.cp-news-hub-title {
			margin: auto;
			padding: 25px 7.3% 23px 7.3%;
			text-align: left;
			max-width: 1612px;
		}
	}

	&-pagination {
		&-d {
			align-items: center;
			column-gap: 16px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			width: 100%;
			@include mq($until: tablet) {
				display: none;
			}
		}

		&-m {
			column-gap: 16px;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			width: 100%;
			align-items: center;
			@include mq($from: tablet) {
				display: none;
			}
		}
	}

	&-nav-page-number {
		cursor: pointer;

		&:hover {
			text-underline-offset: 5px;
			text-decoration: underline 2px solid $color-black;

			&.page-number-ellipse {
				text-decoration: none !important;
			}
		}

		&.page-number-ellipse {
			cursor: none;
			pointer-events: none;
		}

		&.page-number-active {
			text-underline-offset: 5px;
			text-decoration: underline 2px solid $color-orange;
		}
	}

	&-featured-article {
		align-items: stretch;
		background-color: #d3d3d3;
		border-radius: 7px;
		column-gap: 50px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin: auto auto 6%;
		max-width: 1612px;
		color: $color-black;
		transition: 1s;

		&:hover {
			background-color: $color-orange;
			color: $color-white;
			transition: 1s;

			p {
				transition: unset;
				text-decoration: none !important;
			}

			.cp-news-hub-featured-article-img {
				transform: scale(1.1);
				transition: 1s;
			}
		}

		@include mq($from: desktop-ultra-wide) {
			width: 90%;
		}

		@include mq($until: tablet) {
			align-items: center;
			flex-direction: column;
			margin: auto auto 20%;
			row-gap: 5px;
		}

		&-img {
			height: 100%;
			width: 100%;
			transform: scale(1);
			transition: 1s;

			@include mq($until: tablet) {
				width: 100%;
			}

			&-container {
				width: 45%;
				overflow: hidden;

				@include mq($until: tablet) {
					width: 100%;
				}
			}
		}

		&-description {
			width: 90%;
		}

		&-content {
			padding-bottom: 19px;
			padding-top: 17px;
			width: 90%;

			@include mq($until: desktop-wider) {
				padding-bottom: 17px;
				padding-top: 10px;
				width: 90%;
			}

			.featured-article-title {
				font-size: 2.157rem;
			}

			&-container {
				align-items: flex-start;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-right: 40px;
				width: 60%;
				min-height: 275px;

				@include mq($from: 1919px) {
					min-height: 315px;
				}

				@include mq($until: tablet) {
					min-height: unset;
					width: 100%;
					padding: 19px;
				}
			}
		}
	}

	&-grid {
		&-container {
			align-items: stretch;
			column-gap: 30px;
			display: grid;
			grid-template-columns: 23.3% 23.3% 23.3% 23.3%;
			flex-wrap: wrap;
			justify-content: flex-start;
			padding-bottom: 70px;
			row-gap: 40px;

			@include mq($until: desktop-ultra-wide) {
				grid-template-columns: 31.33% 31.33% 31.33%;
			}

			@include mq($until: tablet) {
				grid-template-columns: 100%;
			}
		}

		&-inner-container {
			align-items: stretch;
			border-radius: 10px;
			display: flex;
			flex-direction: column;
			overflow: hidden;
			justify-content: flex-start;

			&:hover {
				.news-title,
				.news-description,
				.news-author,
				.news-date {
					text-decoration: none !important;
				}

				.news-date,
				.news-author {
					color: $color-white;
				}

				.news-image {
					transition: 1s;
					transform: scale(1.1);
					
				}

				.news-text-container {
					background-color: $color-orange;
					transition: background 0.7s;
				}
			}

			.news {
				&-image {
					border-radius: 5px 5px 0 0;
					transition: 1s;
					transform: scale(1);
					height: 100%;
					width: 100%;

					&-container {
						height: 75%;
						min-height: 40%;
						overflow: hidden;
					}
				}

				&-title,
				&-description,
				&-author,
				&-date {
					color: $color-white;
				}

				&-description {
					padding-top: 25px;
					width: 100%;
				}

				&-title {
					align-items: flex-start;
					display: flex;
					flex-direction: column;
					font-size: 1.5rem;
					padding-top: 20px;
					row-gap: 10px;
				}

				&-author,
				&-date {
					color: $color-orange;
					font-size: 0.9rem;
					transition: color 0.7s;
					line-height: 5px;
				}

				&-text-container {
					align-items: flex-start;
					background-color: #474747;
					display: flex;
					flex-direction: column;
					height: 100%;
					justify-content: flex-start;
					padding: 30px 25px;
					transition: background 0.7s;
					width: 100%;
				}
			}
		}
	}
}

.landing {
	&-pagination {
		&-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			column-gap: 22px;
		}

		&-number {
			&-container {
				align-items: center;
				display: flex;
				flex-direction: row;
				column-gap: 8px;
			}

			padding: 4px 13px 4px;
			font-size: 19px;
			font-style: normal;
			font-weight: 400;
			line-height: 28px;
			color: $color-white;
			background: $color-black;
			border-radius: 100px;
		}
	}
}
