@font-face {
    font-family: 'DIN Next Pro Black';
    src: url("/src/scss/fonts/DINNextLTPro-Black.woff2") format('woff2'),
     url("/src/scss/fonts/DINNextLTPro-Black.woff") format('woff'),
    url("/src/scss/fonts/DINNextLTPro-Black.ttf") format('ttf');
    font-syle: normal;
}

@font-face {
    font-family: 'DIN Next Pro Bold';
    src: url("/src/scss/fonts/DINNextLTPro-Bold.woff2") format('woff2'), 
    url("/src/scss/fonts/DINNextLTPro-Bold.woff") format('woff'),
    url("/src/scss/fonts/DINNextLTPro-Bold.ttf") format('ttf');
    font-syle: normal;
}


@font-face {
    font-family: 'DIN Next Pro Heavy';
    src: url("/src/scss/fonts/DINNextLTPro-Heavy.woff2") format('woff2'),
     url("/src/scss/fonts/DINNextLTPro-Heavy.woff") format('woff'),
     url("/src/scss/fonts/DINNextLTPro-Heavy.ttf") format('ttf');
    font-syle: normal;
}

@font-face {
    font-family: 'DIN Next Pro Light';
    src: url('/src/scss/fonts/DINNextLTPro-Light.woff2') format('woff2'), 
    url('/src/scss/fonts/DINNextLTPro-Light.woff') format('woff'),
    url('/src/scss/fonts/DINNextLTPro-Light.ttf') format('ttf');
    font-syle: normal;
}

@font-face {
    font-family: 'DIN Next Pro Regular';
    src: url('/src/scss/fonts/DINNextLTPro-Regular.woff2') format('woff2'), 
    url('/src/scss/fonts/DINNextLTPro-Regular.woff') format('woff'),
    url('/src/scss/fonts/DINNextLTPro-Regular.ttf') format('ttf');
    font-syle: normal;
}

@font-face {
    font-family: 'DIN Next Pro Ultralight';
    src: url('/src/scss/fonts/DINNextLTPro-UltraLight.woff2') format('woff2'), 
    url('/src/scss/fonts/DINNextLTPro-UltraLight.woff') format('woff'),
    url('/src/scss/fonts/DINNextLTPro-UltraLight.ttf') format('ttf');
    font-syle: normal;
}
