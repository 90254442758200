.cp-footer {
	background: $color-dark-grey;

	&-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex-wrap: nowrap;
	}

	&-top-container {
		align-items: flex-start;
		column-gap: 86px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		margin: auto;
		max-width: 1612px;
		padding: 3% 5%;
		width: 100%;

		@include mq($from: desktop-largest-screen) {
			column-gap: 154px;
			padding: 3% 0%;
		}

		@include mq($until: tablet) {
			align-items: center;
			flex-direction: column;
			padding: 25px;
			row-gap: 20px;
		}

		.image-container {
			width: 25%;

			@include mq($until: tablet) {
				width: 100%;
			}

			img {
				width: 100%;
				height: 100%;
			}
		}

		&-columns {
			column-gap: 73px;
			display: flex;
			justify-content: space-between;

			@include mq($from: desktop-largest-screen) {
				column-gap: 113px;
			}

			@include mq($until: tablet) {
				flex-direction: column;
				align-items: flex-start;
				row-gap: 40px;
				width: 100%;
			}
		}
	}

	&-bottom-inner-container {
		max-width: 1612px;
		margin: auto;
		width: 100%;
		padding: 10px 75px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		@include mq($from: desktop-largest-screen) {
			padding: 10px 0.1%;
		}

		@include mq($from: tablet, $until: desktop) {
			padding: 10px 75px;
		}

		@include mq($until: tablet) {
			flex-direction: column;
			padding: 42px 25px;
			row-gap: 35px;
		}
	}

	&-bottom-container {
		background: $color-darkest-grey;
		width: 100%;

		&-left {
			align-items: flex-end;
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: flex-start;
			column-gap: 30px;

			@include mq($from: tablet, $until: desktop) {
				column-gap: 26px;
			}

			@include mq($until: tablet) {
				align-items: center;
				flex-direction: column-reverse;
				row-gap: 40px;
			}

			.cp-footer-links {
				align-items: center;
				column-gap: 1%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				width: 28%;

				@include mq($from: tablet, $until: tablet-wide) {
					width: 98%;
				}

				@include mq($until: tablet) {
					flex-direction: column;
					width: 100%;
					row-gap: 20px;
				}
				.el-footer-links {
					margin-bottom: 0;
					a {
						font-family: "DIN Next Pro Light";
						font-size: 15px;
					}

					&:hover {
						a {
							text-decoration: none;
							color: $color-orange;
						}
					}
				}
			}
		}

		.cp-footer-social-links {
			align-items: flex-start;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			width: 25%;
			column-gap: 2rem;

			.el-social-links {
				width: 1.5rem;
			}
			@include mq($until: tablet) {
				justify-content: center;
				width: 100%;
			}
		}
	}

	&-copyright {
		color: $color-white;
		font-family: "DIN Next Pro Regular";
		font-size: 11px;
	}
}
