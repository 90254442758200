.cp-long-hero {
	background: $color-light-grey;
	border-bottom: 1px solid #e3e3e3;
	margin-bottom: -0.3%;

	&-container {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		max-width: 1612px;
		margin: auto;
	}

	&-top-container {
		align-items: flex-end;
		column-gap: 5%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		padding: 4% 0 8% 12%;
		position: relative;
		width: 79.3%;

		@include mq($until: 1369px) {
			align-items: flex-start;
			flex-direction: column;
			padding: 4% 0 8% 7%;
			width: 94.3%;
		}
	}

	&-bottom-container {
		width: 100%;
		position: relative;

		img {
			background: #1d1d1d;
			border-radius: 40px 40px 0 0;
			display: flex;
			margin: auto;
			padding: 30px 30px 0;
			width: 85%;
			height: 100%;

			@include mq($from: 1920px) {
				border-radius: 40px 40px 0 0;
				padding: 45px 35px 0;
			}

			@include mq($until: desktop) {
				border-radius: 30px 30px 0 0;
			}

			@include mq($until: 770px) {
				padding: 13px 13px 0;
			}

			@include mq($until: 490px) {
				border-radius: 15px 15px 0 0;
				padding: 30px 15px 0;
				width: 100%;
			}
		}
	}

	&-title {
		text-align: left;
	}

	&-subtitle {
		text-align: left;
		font-family: "DIN Next Pro Light";

		&-container {
			align-items: flex-start;
			bottom: 65px;
			display: flex;
			flex-direction: column;
			position: absolute;
			right: -87px;
			row-gap: 15px;
			width: 41.5%;

			@include mq($from: desktop, $until: desktop-mid) {
				right: 0;
			}

			@include mq($until: desktop) {
				position: relative;
				width: 100%;
				right: unset;
				bottom: unset;
			}
		}
	}

	&-btn {
		align-self: self-end;

		@include mq($until: 1369px) {
			align-self: self-start;
		}

		a {
			display: flex;
			flex-direction: row;
			column-gap: 19px;
			transition: 1s;
		}
	}
}

.cp-gov-long-hero {
	&-container {
		align-items: stretch;
		column-gap: 9%;
		display: flex;
		padding: 8% 7.3% 0;
		flex-direction: column;
		justify-content: center;
		max-width: 1612px;
		margin: auto;

		@include mq($from: desktop-standard-wide) {
			padding: 8% 0 0;
		}
	}

	&-top-container {
		align-items: stretch;
		column-gap: 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 10%;

		@include mq($until: desktop) {
			align-items: flex-start;
			flex-direction: column;
			row-gap: 20px;
		}
	}

	&-title {
		color: $color-black;
		text-align: left;
		width: 88%;
		font-size: 6.209rem;

		@include mq($until: desktop) {
			width: 100%;
		}

		&-container {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			row-gap: 22px;
			width: 56%;

			@include mq($until: desktop) {
				width: 100%;
			}

			.page-name {
				color: $color-orange;
			}
		}
	}

	&-subtitle {
		width: 100%;
		&-container {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			row-gap: 30px;
			width: 40.4%;

			@include mq($until: desktop) {
				width: 100%;
			}
		}
	}

	&-img {
		width: 100%;
		height: 100%;
		border-radius: 10px;
	}
}

.cp-learn-long-hero-cta {
	width: 45%;

	@include mq($until: tablet) {
		width: 91%;
	}
}

.cp-learn-long-hero {
	background-color: $color-dark-grey;

	&-image {
		bottom: -65px;
		height: 100%;
		position: absolute;
		right: 125px;
		width: 100%;
		width: 115%;
		z-index: 1;

		@include mq($from: desktop-largest-screen) {
			bottom: -60px;
			height: 100%;
			position: absolute;
			right: 260px;
			width: 100%;
			width: 86%;
			z-index: 1;
		}

		@include mq($until: desktop-wide) {
			bottom: -135px;
			height: 100%;
			position: absolute;
			right: 100px;
			width: 100%;
			width: 150%;
			z-index: 1;
		}

		@include mq($until: desktop) {
			bottom: -40%;
			right: 77px;
			width: 140%;
		}

		@include mq($from: 912px, $until: tablet-wide) {
			bottom: -41%;
			position: absolute;
			right: 77px;
			width: 275%;
			z-index: 1;
		}

		@include mq($until: 912px) {
			bottom: -52%;
			position: absolute;
			right: 40px;
			width: 255%;
			z-index: 1;
		}

		@include mq($until: tablet) {
			display: none;
		}
	}

	&-btn {
		&-container {
			display: flex;
			row-gap: 10px;
			flex-direction: column;
			align-items: flex-start;
		}

		a {
			color: $color-white;
		}
	}

	&-bottom-text {
		color: $color-white;
		font-size: 12px;
		width: 60%;

		@include mq($until: tablet) {
			width: 95%;
		}
	}

	&-container {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: space-between;
		column-gap: 7.4%;
		overflow: hidden;

		@include mq($until: tablet) {
			flex-direction: column;
			width: 100%;
			position: relative;
		}
	}

	&-top-container {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		max-width: 1612px;
		padding: 63px 0 76px 105px;
		row-gap: 25px;
		width: 63%;

		@include mq($from: desktop-standard-wide) {
			padding: 85px 0 85px 235px;
		}
		@include mq($from: desktop-largest-screen) {
			padding: 85px 0 85px 465px;
		}

		@include mq($from: desktop-wide) {
			width: 57%;
		}

		@include mq($from: tablet, $until: tablet-wide) {
			width: 125%;
		}

		@include mq($until: tablet) {
			padding: 66px 0 63px 22px;
			width: 100%;
		}
	}

	&-title {
		text-align: left;
		width: 60%;
		color: $color-white;

		@include mq($until: tablet-wide) {
			width: 100%;
		}

		@include mq($from: desktop-wider) {
			width: 100%;
		}
	}

	&-subtitle {
		color: $color-white;
		&-container {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			row-gap: 25px;

			.learn-hero-cta {
				color: $color-white;
			}
		}

		@include mq($until: desktop) {
			width: 73%;
		}

		@include mq($until: tablet) {
			width: 99%;
		}

		ul {
			list-style-type: none;

			li:before {
				content: "\2014";
				position: absolute;
				margin-left: -20px;
			}
		}
	}

	&-bottom-container {
		position: relative;
		width: 33.3%;

		@include mq($from: tablet, $until: tablet-wide) {
			width: 29.3%;
		}

		.learn-long-hero-background {
			background: $color-orange;
			height: 100%;
			left: -40px;
			pointer-events: none;
			position: absolute;
			transform: skew(312deg);
			width: 95vw;
		}
	}
}

.parallelogram {
	width: 100px;
	height: 50px;
	transform: skew(20deg);
	background: #555;
}

.register-cta {
	&-form {
		align-self: self-end;

		&-text {
			column-gap: 19px;
			display: flex;
			flex-direction: row;
			transition: 1s;
		}
	}
}
