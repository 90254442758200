.cp-product-page {
	&-hero {
		padding: 0;
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: right center;

		&-container {
			background: $color-white;
			height: 268px;
			padding: 5%;
			width: 31%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			clip-path: circle(0% at 0 0%);
			transition: clip-path 2s ease-in-out;

			&.active {
				clip-path: circle(113% at 0 50%);
				transition: clip-path ease-in-out 2s;

				@include mq($until: tablet) {
					clip-path: circle(90% at 0 50%);
				}
			}

			@include mq($from: tablet, $until: desktop) {
				clip-path: circle(111% at 0 62%);
				width: 46%;
			}

			@include mq($until: tablet) {
				height: 100%;
				width: 80%;
			}

			.product-name {
				width: 100%;

				&.first {
					color: $color-black;

					@include mq($until: tablet) {
						font-size: 2.157rem;
					}
				}
				&.second {
					color: $color-orange;
					@include mq($until: tablet) {
						font-size: 2.157rem;
					}
				}
			}
			.product-subtitle {
				padding-top: 7px;
				font-family: DIN Next Pro Regular;

				@include mq($until: tablet) {
					width: 88%;
				}
			}
		}
	}

	&-products {
		.obashi-btn {
			a {
				color: $color-white;
			}
		}

		background-color: $color-dark-grey;

		&-container {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			padding: 50px 5%;
			position: relative;
			row-gap: 15px;
			width: 67%;

			@include mq($from: desktop-largest-screen) {
				padding: 50px 0%;
				padding-right: 185px;
			}

			@include mq($from: desktop-standard-wide, $until: desktop-largest-screen) {
				padding: 50px 12.5%;
				padding-right: 50px;
			}

			@include mq($from: tablet-wide, $until: desktop) {
				width: 100%;
				padding: 50px 8%;
				padding-right: 43px;
			}

			@include mq($until: tablet-wide) {
				padding: 30px 25px 60px;
				row-gap: 19px;
				width: 100%;
			}

			@include mq($from: tablet, $until: tablet-wide) {
				padding: 30px 8% 60px;
			}
		}

		&-title,
		&-subtitle {
			color: $color-white;
		}

		&-subtitle {
			width: 69%;
			font-size: 20px;
		}

		&-title-container {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			row-gap: 15px;
		}
	}

	&-product-container {
		display: flex;
		flex-direction: row;
		margin: auto;
		max-width: 2080px;
		position: relative;
		width: 100%;
		margin-right: 0;
		column-gap: 65px;

		@include mq($until: tablet-wide) {
			flex-direction: column;
		}
	}

	&-learn {
		&-container {
			background: $color-orange;
			border-radius: 30px 0 0 30px;
			color: $color-white;
			display: flex;
			flex-direction: column;
			margin: 50px 0;
			padding: 25px 100px 35px 4%;
			margin: auto;
			row-gap: 10px;
			width: 35%;
			align-items: flex-start;
			justify-content: space-evenly;

			.banner-text {
				&-title {
					font-size: 3rem;
				}
				&-subtitle {
					font-size: 1.07rem;
				}
			}


			@include mq($from:desktop-standard-wide){
				padding: 30px 185px 37px 4%
			}

			@include mq($until:desktop-wide){
				padding: 25px 54px 35px 4%;
			}



			@include mq($until: tablet-wide) {
				margin: 0;
				width: 100%;
				border-radius: 0;
				padding: 41px 25px 42px 25px;
			}

			@include mq($from: tablet, $until: tablet-wide) {
				padding: 41px 14% 42px;
			}
		}
	}

	&-payment-container {
		align-items: flex-end;
		column-gap: 90px;
		display: flex;
		flex-direction: row;
		height: 100%;
		justify-content: flex-start;
		position: relative;
		width: 100%;

		@include mq($from: tablet, $until: desktop) {
			flex-direction: column;
			row-gap: 18px;
		}

		@include mq($until: tablet) {
			flex-direction: column;
			row-gap: 50px;
		}
	}

	&-item {
		align-items: stretch;
		display: flex;
		flex-direction: column;
		justify-content: center;
		row-gap: 25px;
		width: 46%;

		@include mq($until: desktop) {
			width: 100%;
		}

		&-buy {
			display: flex;
			flex-direction: column;
			color: $color-white;

			@include mq($until: desktop) {
				align-items: flex-start;
				margin-top: 0;
				width: 100%;
			}
		}
	}

	&-accepted-payment {
		width: 100%;
		color: $color-white;
		text-align: right;
		padding-top: 9px;
	}

	&-outer-container {
		max-width: 1612px;
		margin: auto;
	}
}

.el-price-element {
	&-btn {
		--bs-btn-padding-x: 20px;
		--bs-btn-padding-y: 12px;
		color: $color-white;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		text-align: left;
		width: 100%;
		align-items: baseline;

		.best-value {
			color: $color-white;
			display: flex;
			column-gap: 8px;
			align-items: baseline;
			justify-content: space-around;
		}

		&:hover {
			color: $color-white;
		}
	}

	&-buy {
		align-items: flex-start;
		color: #fff;
		display: flex;
		flex-direction: column;
		margin-top: -23px;
		width: 46%;

		@include mq($until: desktop) {
			width: 100%;
			margin-top: 0;
		}

		&-text {
			width: 100%;
			display: flex;
			justify-content: space-between;

			color: $color-white !important;
			padding: 5px;
		}

		&.year {
			display: none;
		}

		&-link {
			width: 100%;
			color: $color-white;
		}

		&-btn {
			align-items: center;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
			color: $color-white;

			&-text {
				width: 100%;
				text-align: left;
				color: $color-white;
				font-family: DIN Next Pro Light;
				font-size: 20px;

			}

			font-family: DIN Next Pro Light;
			font-size: 18px;
			font-style: normal;
			line-height: normal;
			text-decoration: none;

			a {
				color: $color-white;
				&:hover {
					color: $color-white;
				}
			}

			&-form {
				width: 100%;
			}
		}

		&-savings {
			font-size: 16px;
			font-family: "DIN Next Pro Regular";
			color: $color-orange;
		}

		&-price-container {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			justify-content: center;
			row-gap: 4px;
			padding-bottom: 10px;

			.price {
				height: 45px;
			}

			&.month {
				padding-bottom: 34px;
			}

			.title-container {
				align-items: baseline;
				display: flex;
				justify-content: flex-start;

				.price-details {
					font-size: 1.02rem;
				}
			}
		}
	}

	&-prices {
		padding: 6% 5%;
	}

	.best-value {
		color: $color-white;
		font-size: 15px;
	}
}

.payment-display {
	display: flex !important;
}

.current-choice {
	background-color: $color-orange;
	color: $color-white;
}

.banner-animate {
	opacity: 1 !important;
	transform: none !important;
}

.container {
	width: 100%;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}
