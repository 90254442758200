.el-system-requirements {
    margin: 65px 0px;


    td, table {
        font-family: "DIN Next Pro Regular";
        border: 1pt solid $color-orange!important;
        background-color: $color-white!important;
    }

    &-title {
        margin-bottom: 50px;
     font-family: "DIN Next Pro Bold";
    }
}