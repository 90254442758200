.thank-you-page {
    max-width: 1612px;
    margin: auto;
    padding: 140px;

    &-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    &-cta {
        padding-top: 45px;
    }
}