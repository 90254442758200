.pricing-page {
	&-container {
		align-items: center;
		display: flex;
		flex-direction: column;
		padding: 70px 105px;
		row-gap: 23px;
		background: $color-white;

		@include mq($until: desktop) {
			padding: 70px 25px;
		}
	}

	&-title {
		padding: 0px 105px;

		@include mq($until: desktop) {
			padding: 0;
		}
	}

	&-subtitle {
		@include mq($until: tablet-wide) {
			text-align: center;
		}
	}

	&-price-btn {
		margin-bottom: 50px;
		margin-top: 21px;
	}

	&-products {
		align-items: flex-start;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		width: 100%;
		max-width: 1600px;

		@include mq($from: tablet, $until: desktop) {
			align-items: center;

			flex-direction: column;
			row-gap: 40px;
		}

		@include mq($until: tablet) {
			flex-direction: column;
			row-gap: 40px;
		}

		&.desktop {
			display: flex;
			@include mq($until: desktop) {
				display: none;
			}
		}

		&.mobile {
			display: none;
			@include mq($until: desktop) {
				display: flex;
			}

			.splide__slide {
				opacity: 1;
			}

			@include mq($from: tablet, $until: desktop) {
				.splide__arrow--prev {
				    bottom: -83px;
    				top: unset!important;
				}

				.splide__arrow--next {
					right: 0!important;
					left: unset!important;
				}
			}
		}
	}

	&-yearly,
	&-monthly {
		z-index: 20;
		transition: all 0.4s ease-in-out 0s;
	}

	&-monthly {
		margin-right: 9px;
		margin-left: -7px;
	}

	&-yearly {
		color: $color-white;
		margin-left: -8px;
	}
}

.switch {
	display: inline-block;
	height: 53px;
	position: relative;
	width: 200px;
	margin-bottom: 25px;
}

.switch input {
	display: none;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $color-light-grey;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 34px;
	column-gap: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	-webkit-box-shadow: -1px -1px 11px 1px rgba(29, 29, 29, 0.19);
	-moz-box-shadow: -1px -1px 11px 1px rgba(29, 29, 29, 0.19);
	box-shadow: -1px -1px 11px 1px rgba(29, 29, 29, 0.19);
}

.slider:before {
	position: absolute;
	background-color: $color-orange;
	border-radius: 50px;
	content: "";
	height: 100%;
	width: 51%;
	left: 0;
	z-index: 0;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: $color-light-grey;
}

input:focus + .slider {
	box-shadow: 0 0 1px $color-orange;
}

input:checked + .slider:before {
	-webkit-transform: translateX(97px);
	-ms-transform: translateX(97px);
	transform: translateX(97px);

	div > .pricing-page-monthly {
		color: $color-white;
	}
}

/*------ ADDED CSS ---------*/
.slider:after {
	content: "";
	display: block;
	font-size: 10px;
	left: 28%;
	position: absolute;
	top: 50%;
	color: $color-black;
	z-index: 50;
	transform: translate(-50%, -50%);
}
input:checked + .slider:after {
	.pricing-page-monthly {
		color: $color-black;
	}
	content: "";
}
