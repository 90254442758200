.cp-short-hero {
    padding: 50px 100px;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
    flex-wrap: nowrap;

    @include mq($until:tablet){
        padding: 25px 25px;
    }

    &-title {
        font-family: 'DIN Next Pro Regular';
        width: 68%;
        text-align: center;
        margin:1rem 0rem 2rem;

        @include mq($until:tablet){
            width: 100%;
            text-align: left;
        }
    }

    &-copy {
        text-align: center;
        width: 64%;

        @include mq($until:tablet){
            text-align: left;
            width: 100%;
        }

        p { 
            font-family: 'DIN Next Pro Light';
            margin-bottom: 0.5rem
        }
    }
}