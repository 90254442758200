.cp-feature-billboard {
	align-items: center;
	column-gap: 108px;
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	padding: 39px 7.3% 50px 0;
	position: relative;
	background: $color-white;


	@include mq($from:2000px){
		padding:39px 18% 50px 0px;
		column-gap: 50px;
	}

	@include mq($until: desktop-ultra-wide) {
		column-gap: 78px;
	}

	@include mq($until: desktop-mid) {
		column-gap: 44px;
	}

	@include mq($from: tablet, $until: desktop) {
		row-gap: 42px;
	}	
	
	@include mq($until: tablet-wide) {
		align-items: flex-start;
		flex-direction: column-reverse;
		margin-right: 0;
	}



	&-left {
		width: 54%;

		@include mq($from: 2000px) {
		    width: 50%;
		}

		@include mq($from:tablet, $until:tablet-wide){
			margin: 0 77px;
			width: 87%;
		}

		@include mq($until: tablet) {
			margin: 40px 0 0 25px;
			width: 95%;
		}
	}

	&-title {
		@include mq($until: tablet) {
			text-align: left;
		}
	}

	&-right {
		align-items: flex-start;
		background: $color-orange;
		border-radius: 0 40px 40px 0;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		row-gap: 1px;
		padding: 40px 45px 47px 92px;
		width: 44%;

		@include mq($from: 2000px) {
			padding:65px 45px 80px 28%;
			width: 50%;
		}

		@include mq($from:desktop, $until:2000px){
			padding: 50px 45px 50px 16%;

		}

		@include mq($from:tablet-wide, $until:desktop){
			padding: 45px 35px 50px 8%
		}

		@include mq($until:tablet-wide){
			padding: 35px 44px 37px 27%;
			width: 84%;
		}

		@include mq($until:tablet){
			border-radius: 0 40px 40px 0;
			padding: 50px 10% 51px 7%;
			width: 100%;
		}
	}

	&-title,
	&-subtitle {
		color: $color-white;
	}

	&-subtitle {
		font-size: 2rem;
	}

	&-items {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		row-gap: 3rem;

		@include mq($until: desktop) {
			justify-content: space-around;
		}


		@include mq($from:tablet, $until:desktop){
			justify-content: space-between;
			align-items: flex-end;
		}

		@include mq($until: tablet) {
			align-items: flex-start;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: flex-start;

		}
	}
}

.el-feature-billboard {
	align-items: flex-start;
	column-gap: 29px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	row-gap: 15px;
	width: 43%;

	@include mq($until: desktop-mid) {
		width: 46%;
	}

	@include mq($from:tablet, $until:desktop){
		width: 44%;
	}

	@include mq($until: tablet) {
		width: 100%;
		justify-content: space-around;
	}

	img {
		width: 40px;

		@include mq($from: desktop-standard-wide) {
			width: 60px;
			height: 60px;
		}

		@include mq($until: desktop-mid) {
			height: 35px;
			width: 35px;
		}

		@include mq($until: tablet) {
			width: unset;
			height: unset;
		}
	}

	&-container {
		display: flex;
		row-gap: 7px;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		width: 53%;
	}

	&-text-container {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		row-gap: 5px;
		width: 100%;

		@include mq($until: tablet) {
			row-gap: 13px;
			width: 95%;
		}
	}

	&-title {
		width: 150%;
		@include mq($until: desktop) {
			width: 100%;
			font-family: "DIN Next Pro Light";
		}
	}
}
