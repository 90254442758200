.system-requirements {
	&-title {
		width: 100%;
		padding: 30px 12px;
		background: $color-orange;
		color: white;
	}

	&-container {
		margin:0 7.3%;
	}
}