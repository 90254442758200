.el-footer-columns {
	display: flex;
	flex-direction: column;
	row-gap: 20px;
	align-items: flex-start;
	justify-content: flex-start;

	@include mq($until: tablet) {
		align-items: center;
		width: 100%;
	}

	&-title {
		font-family: DIN Next Pro Regular;
		font-size: 20px;

		color: $color-white;
		text-decoration: underline 2px solid $color-white;
		text-underline-offset: 5px;
		-webkit-text-decoration: underline;
	}

	&-items {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		row-gap: 15px;

		@include mq($until: tablet) {
			align-items: center;
		}
	}
}

.el-footer-links {
	a {
		color: $color-white;
		font-family: "DIN Next Pro Light";
	    font-size: 18px;

		&:hover {
			text-decoration: underline 2px solid $color-white;
			text-underline-offset: 6px;
			transition: all ease-in-out 0.5s;
			-webkit-text-decoration: underline;
		}
	}
}
