.cp-feature-component {
    background: #474747;
	&-items {
		align-content: space-between;
		align-items: start;
		display: grid;
		grid-template-columns: 33.3% 33.3% 33.3%;
		justify-content: center;
		justify-items: start;
		padding: 45px 74px 65px;
		row-gap: 35px;
		position: relative;
		max-width: 1612px;
		margin: auto;

		
		@include mq($from:tablet, $until:desktop){
			row-gap: 45px;
			column-gap: 45px;
		}

		@include mq($until:tablet){
			grid-template-columns: 100%;
			padding: 45px 25px 50px;
		}
	}
}
