.cp-single-heading {
   padding: 4% 7%;
   padding-bottom: 10px;

@include mq($from:desktop-standard-wide){
   max-width: 1612px;
   margin:auto;
   padding:4% 0% 10px;
}

 @include mq($from:tablet, $until:desktop){
    padding: 4% 9.4% 1%;
 }

 @include mq($until:tablet){
    padding: 25px 25px 10px;
    }
}