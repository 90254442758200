.cp-spacer {
    &-large {
        padding:10rem;
    }

    &-medium {
        padding: 8rem;
    }

    &-small {
        padding:5rem;
    }
}