.cp-double-paragraph {
    margin: auto;
    padding: 55px 0;
    width: 85%;
    max-width: 1612px;

    @include mq($from:tablet, $until:desktop){
        width: 84%;
    }

    &-title {
        text-align: center;
         margin-bottom: 40px;
    }

    &-content {
        display: flex;
        flex-direction: row;
        width: 83%;
        margin: auto;
        text-align: justify;
        column-gap: 114px;
        align-items: flex-start;

        @include mq($until:desktop){
            column-gap: 80px;
            width: 96%;
        }

        @include mq($until:tablet){
            flex-direction: column;
            row-gap: 55px;
        }


        &-item {
            width: 50%;

            @include mq($until:tablet){
                width: 100%;
            }
        }
    }
}