.feature-page {
	&-image {
                padding: 5rem 5rem;
                padding-bottom: 2rem;
		img {
			width: 100%;
			height: 100%;
		}
	}

	&-items {
                align-content: space-between;
                align-items: start;
                display: grid;
                grid-template-columns: 33.3% 33.3% 33.3%;
                justify-content: center;
                justify-items: start;
                padding: 3rem 5rem;
                row-gap: 2rem;
 
	}
}
