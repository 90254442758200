.cp-fifty-fifty-carousel {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: auto;
    max-width: 1612px;
    padding: 45px 5.3% 65px 7.3%;


	@include mq($from:tablet, $until:desktop){
		padding: 28px 78px 83px 78px;
		flex-direction: column;
	}

	@include mq($until:tablet){
		row-gap: 60px;
		flex-direction: column;
		padding: 28px 26px 73px 25px;
	}


	&-container {
		width: 55%;

		@include mq($until:desktop){
			width: 100%;
		}

		h2 {
			width: 94%;

			@include mq($until:desktop){
				width: 100%;
			}
		}

		.container-body {
		    margin:30px 0px 0px 0px;
			width: 80%;

			@include mq($until:desktop){
				width: 100%;
			}
		}
	}

	&-images-container {
		.cp-fifty-fifty-carousel-images {
			.swiper-wrapper {
				.swiper-slide {		
					height: unset;
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}

	&-thumbnail-container {
		margin-top: 4rem;

		@include mq($until:tablet){
			.splide__arrow {
				display: none;
			}
		}
	}
}

.sliders {
	width:45%;

	@include mq($until:desktop){
		width: 100%;
	}
}


.description-text{
	position: absolute;
    bottom: -2rem;
    font-size: 15px;
	font-family: "DIN Next Pro Regular";
}
