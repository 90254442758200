.cp-multi-item-banner {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
    padding: 3% 7.2%;
	position: relative;
	background:$color-orange;

	@include mq($until: tablet) {
		padding: 25px;
	}

	&-title {
		color: $color-white;
		z-index: 10;
	}

	// .overlay {
	// 	background: rgba(0, 0, 0, 0.5);
	// 	height: 100%;
	// 	left: 0;
	// 	position: absolute;
	// 	top: 0;
	// 	width: 100%;
	// 	z-index: 1;
	// 	pointer-events: none;
	// }

	&-container {
		column-gap: 50px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 20px 0;
		z-index: 2;
		width: 100%;

		@include mq($from:tablet, $until:desktop){
			align-items: center;
			flex-direction: column;
			row-gap: 44px;
		}

		@include mq($until: tablet) {
			align-items: flex-start;
			row-gap: 44px;
			flex-direction: column;
		}
	}

	&-outer-container {
		max-width: 1612px;
		margin: auto;
	}

	&-img {
		min-height: 500px;
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
}
