.login-page {
	&-container {
		align-items: center;
		display: flex;
		flex-direction: column;
		row-gap: 95px;

		@include mq($until: desktop) {
			row-gap: 45px;
		}
	}

	&-top-container {
		color: $color-white;
		background: $color-orange;
		width: 100%;
		margin: auto;

		.login-page-title {
		    margin: auto;
    	    padding: 25px 7.3% 23px 7.3%;
    		max-width: 1612px;
		}
	}

	&-form-container {
		margin: auto;
		padding: 30px 0 135px;
		width: 41%;

		.register-form-success {
			text-align: center;
		}

		.forgot-password-container {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			row-gap: 10px;
		}

		@include mq($until: desktop) {
			padding: 13px 0 10%;
			width: 67%;
		}

		@include mq($until: tablet) {
			padding: 43px 0 34%;
			width: 84%;
		}
	}
}

.login-form {
	&-btn {
		width: 25%;
		align-self: center;
		margin-top: 50px;

		@include mq($until: desktop) {
			width: 100%;
		}
	}

	.login-form-inner-container {
		display: flex;
		flex-direction: column;
		align-items: stretch;

		.form-row {
			&.first-row {
				margin-bottom: 20px;
			}

			&.second-row {
				margin-bottom: 15px;
			}

			&.third-row {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-content: center;
				align-items: flex-start;

				@include mq($until: desktop) {
					row-gap: 10px;
					flex-direction: column;
				}

				.login-links {
					display: flex;
					flex-direction: row;
					column-gap: 20px;
					align-items: flex-end;
					justify-content: flex-end;

					@include mq($until: desktop) {
						align-items: flex-start;
						column-gap: 10px;
					}

					.forgot-pw-link,
					.register-link {
						font-size: 15px;
						color: $color-black;

						&:hover {
							color: $color-orange;
						}
					}
				}

				.form-check {
					margin: 0;
					padding: 0;

					.col {
						text-align: right;
					}
				}
			}
		}
	}

	&-check {
		@include mq($until: desktop) {
			padding-left: 0;
		}
	}

	.form-check-label {
		font-family: "DIN Next Pro Regular";
	}

	.form-error-banner {
		background-color: rgba($color: #ff3838, $alpha: 0.05);
		border: 1px solid lighten(#ff3838, 25%);
		color: #000;
		margin: -30px auto 40px;
		max-width: 50%;
		padding: 14px 20px;
		width: 100%;
		border-radius: 10px;
		
		&.False {
			display: none;
		}

		&.True {
			display: block;
		}

		.alert-title {
			color: #ff3838;
		}
		color: $color-black;
	}
}

.cp-register {
	&-form {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		row-gap: 15px;

		.col-form-label.required:after,
		label.required:after {
			inset: unset !important;
			color: red;
			content: " *" !important;
			position: relative !important;
		}

		.login-form-btn {
			width: 25%;
			margin-top: 30px;

			@include mq($until: desktop) {
				width: 100%;
			}
		}
	}

	&-marketing-terms {
		display: flex;
		row-gap: 15px;
		flex-direction: column;
		align-items: flex-start;

		.terms-validation {
			min-height: 1.5rem;
			padding-left: 1.5em;
			margin-bottom: 0.125rem;
		}

		.form-check {
			display: flex;
			flex-direction: row;
			row-gap: 10px;
			column-gap: 10px;

			&.bottom-text {
				padding-top: 13px;
				width: 75%;
				font-family: "DIN Next Pro Regular";
			}

			a {
				display: inline;
				text-decoration: underline 2px solid $color-orange;
				text-underline-offset: 3px;
				text-decoration: underline;
				text-decoration-color: $color-orange;
				text-decoration-thickness: 2px;
				text-decoration-style: solid;

				&:hover {
					color: $color-orange;
				}
			}
		}
	}

	&-password-requirements {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		row-gap: 5px;

		&-container {
			display: none;
		}

		li {
			align-items: center;
			column-gap: 10px;
			display: flex;
			flex-direction: row;
			margin: 0;

			p {
				font-size: 15px;
			}

			&::before {
				content: "✕";
				color: red;
			}

			&.valid {
				&::before {
					content: "✓";
					color: green;
				}
			}

			&.invalid {
				&::before {
					content: "✕";
					color: red;
				}
			}
		}
	}

	&-first-row,
	&-second-row,
	&-third-row {
		.form-row {
			column-gap: 29px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;

			@include mq($until: desktop) {
				flex-direction: column;
			}

			.col {
				width: 48%;
				row-gap: 5px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-end;

				@include mq($until: desktop) {
					width: 100%;
				}

				input,
				select {
					font-family: "DIN Next Pro Regular";
					font-size: 16px;
				}

				.form-control {
					border: 2px solid $color-orange;

					&:focus {
						box-shadow: 0 0 0 0.25rem #eb87463b;
					}
				}
			}
		}
	}

	&-forth-row,
	&-fifth-row {
		.form-row {
			align-items: stretch;
			row-gap: 12px;
			display: flex;
			justify-content: space-between;
			flex-direction: column;

			.col {
				width: 100%;
				row-gap: 5px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-end;

				input,
				select {
					font-family: "DIN Next Pro Regular";
					font-size: 16px;
				}

				.form-control {
					border: 2px solid $color-orange;

					&:focus {
						box-shadow: 0 0 0 0.25rem #eb87463b;
					}
				}
			}
		}
	}

	.login-form-inputs {
		display: flex;
		row-gap: 5px;
		width: 48%;
		flex-direction: column;
		align-items: flex-start;
	}

	.form-control {
		border-color: $color-orange;
		border-radius: 15px;
		border-width: medium;
		font-size: 15px;

		&.input-validation-error {
			border-color: red($color: #000000);

			.popover {
				transform: translate(310px, 790px) !important;
			}
		}
	}
}

.popover {
	inset: 70px auto auto 0px !important;
	transform: none !important;
	&-arrow {
		display: none !important;
	}

	&-header {
		padding: 10px 20px;
		text-align: center;
		background-color: $color-orange;
		color: $color-white;
	}

	&-body {
		padding: 13px 11px;
		.cp-register-password-requirements-container {
			display: block;
		}
	}
}

.form-control {
	border: 2px solid $color-orange;
	font-family: "DIN Next Pro Regular";
}

.form-control:focus {
	border: 2px solid $color-orange;
	box-shadow: 0 0 0 0.25rem rgba(235, 135, 70, 0.25);
}

.register-modal {
	.modal-body {
		padding: var(--bs-modal-padding);
		a {
			display: inline;
		}
	}
}

.terms_link {
	font-family: DIN Next Pro Regular;
}


.forgot-password-form-success {
	margin: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50vh;
    justify-content: center;
    row-gap: 15px;

	@include mq($until:tablet){
		height: 60vh;
		width: 91%;
		row-gap: 25px
	}

	&-title, &-body{
		text-align: center;
	}
}