.cp-fifty-fifty-billboard-left {
	align-items: stretch;
	display: flex;
	flex-direction: column;

	.cp-fifty-fifty-billboard {
		&-container {
		    align-items: center;
			display: flex;
			flex-direction: row;
			margin: auto;
			max-width: 1612px;
		    padding: 20px 7.3%;
			width: 100%;
			justify-content: space-between;
			padding-bottom: 40px;

			@include mq($from:desktop-standard-wide){
				padding:0;
			}

			@include mq($from:tablet-wide, $until:desktop){
				padding:35px 7.3%;
				align-items: center;
			}

			@include mq($until: tablet-wide) {
				flex-direction: column;
			    padding: 30px 9%;
			}
		}

		&-img {
			display: flex;
			justify-content: space-evenly;
			padding: 50px 0 50px 0;
			width: 49%;

			img {
				@include mq($from:tablet){
					border-radius: 10px;
				}
			}

			@include mq($from:tablet, $until:desktop){
				justify-content: center;
			}


			@include mq($until: desktop) {
				padding: 0;
				width: 100%;
			}
		}

		&-items {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0 0% 0 5%;
			width: 50%;

			@include mq($from:tablet-wide, $until:desktop){
				align-items: flex-start;
				padding: 0 0 0 5%;
				width: 100%;
			}

			@include mq($from: tablet, $until: tablet-wide) {
				padding: 10px 67px 65px;
				width: 100%;
			}

			@include mq($until: tablet) {
				align-items: center;
				justify-content: space-around;
				row-gap: 0px;
				width: 100%;
				padding: 25px 0px 0px;
			}

			button {
				margin-top: 1rem;
			}

			.el-billboard-item {
				width: 90%;
				padding: 3% 0 1%;
				border-bottom: 1px solid $color-lightest-grey;

				&-title {
					padding: 25px 0px;
				}

				@include mq($until: desktop) {
					&-subtitle {
						width: 95%;
					}
					padding: 0% 0 20px;
					width: 100%;
				}
			}

			.el-billboard-item:nth-last-child(2) {
				border-bottom: none;
			}
		}
	}
}

.cp-fifty-fifty-billboard-right {
	align-items: stretch;
	display: flex;
	flex-direction: column;

	.cp-fifty-fifty-billboard {
		&-container {
			max-width: 1612px;
			align-items: center;
			display: flex;
			flex-direction: row-reverse;
			margin: auto;
			max-width: 1612px;
		    padding: 20px 7.3%;
			width: 100%;
			padding-bottom: 40px;

			@include mq($from:desktop-standard-wide){
				padding:0;
			}	

			@include mq($from:tablet-wide, $until:desktop){
				align-items: center;
				padding:35px 7.3%;
			}
				

			@include mq($until:tablet-wide){
				flex-direction: column;
				padding: 30px 9%;
			}

		
		}

		&-img {
			display: flex;
			padding: 50px 0px;
			width: 53%;
			img {
				
				@include mq($from:tablet){
					border-radius: 10px;
					justify-content: center;
				}
			}

			@include mq($from:tablet-wide, $until:desktop){
				width: 50%;
    			height: 50%;
			}

			
			@include mq($from:tablet){
				justify-content: center;
			}

			@include mq($until: tablet-wide) {
				padding: 0;
				width: 100%;
			}
		}

		&-items {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0 5% 0px 0%;
			width: 50%;
			align-items: flex-start;


			@include mq($from:tablet, $until:tablet-wide){
				padding: 40px 51px 75px;
				width:100%;
			}

			@include mq($until: tablet) {
				align-items: center;
				justify-content: space-around;
				row-gap: 0px;
				width: 100%;
				padding: 30px 0px 0px;
			}
			

			button {
				margin-top: 1rem;
				background: inherit;

				@include mq($until: desktop) {
					margin-top: 0;
				}

				@include mq($from:tablet, $until:desktop){
					width: 100%;
				}

				&:hover {
					background-color: $color-orange;
				}
			}

			.el-billboard-item {
				padding: 3% 0 1%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				row-gap: 5px;
				border-bottom: 1px solid $color-lightest-grey;

				@include mq($until: desktop) {
					padding: 0% 0 20px;
					width: 100%;
				}

				&-title {
					padding: 8px 0;
				}
			}

			.el-billboard-item:nth-last-child(2) {
				border-bottom: none;
			}
		}
	}
}

.cp-fifty-fifty-billboard-btn {
	.obashi-btn {
		--bs-btn-padding-x: 19px;
		--bs-btn-padding-y: 9px;

		&.white {
			background-color: $color-white;

			&:hover {
				background-color: $color-orange;
			}
		}

		&.off-white {
			background-color: $color-light-grey;

			&:hover {
				background-color: $color-orange;
			}
		}

		@include mq($until: desktop) {
			width: 100%;
		}
	}

	@include mq($from: tablet, $until: desktop) {
		width: 40%;
	}

	@include mq($until: tablet) {
		width: 100%;
	}
}

.el-billboard-image {
	width: 100%;
	height: 100%;
}
