// .cp-header {
// 	&-container {
// 		align-items: center;
// 		background: $color-dark-grey;
// 		-webkit-box-shadow: 0 0 18px 1px #000;
// 		box-shadow: 0 0 18px 1px #000;
// 		display: flex;
// 		flex-direction: row;
// 		justify-content: space-between;
// 		padding: 0;
// 	}

// 	&-logo {
//         margin-left: 1%;
//         width: 100%;

// 		h1 {
// 			color:white;
// 			font-style: italic;
// 			font-size: 1.5rem;
// 		}

// 		a {
// 			display: block;

// 			img {
// 				width: 100%;
// 				height: 100%;
// 			}
// 		}
// 	}

// 	&-links-container {
// 		align-items: center;
// 		column-gap: 2rem;
// 		display: flex;
// 		flex-direction: row;
// 		height: 100%;
// 		justify-content: flex-end;
// 		width: 50%;

// 		a {
// 			color: white;
// 			font-size: 1rem;
// 			&:hover {
// 				color: $color-pink;
// 				text-underline-offset: 0.2rem;
// 				text-decoration: underline 2px;
// 			}
// 		}

//         .sign-in {
// 			align-items: center;
//             border-left: $color-white 3px solid;
// 		    display: flex;
// 			flex-direction: row;
// 			justify-content: space-around;
// 			padding: 1rem 3rem;

// 			&-container {
// 				display: flex;
// 				flex-direction: row;
// 				justify-content: space-around;
// 				row-gap: 1rem;
// 				align-items: center;
// 			}

// 			svg {
// 				margin-right: 1rem;
// 				fill: white;
// 			}

//             a {
//                 text-decoration: none;
//                 font-size: 15px;

//                 &:before{
//                     background-image: url("/src/img/icons/person-icon.svg");
// 					width: 50px;
// 					height: 50px;
//                 }
//             }
//         }
// 	}
// }

// .c-header {
// 	z-index: 2;
// 	position: fixed;
// 	width: 100%;
// 	top: 0;
// 	transition: all 0.8s ease;
// 	flex-direction: row;
// 	display: flex;
// 	flex: 1 1 100%;
// 	justify-content: flex-end;
// 	background-color: transparent;
// }

// .c-header-entrance{
// 	opacity: 0;
// 	pointer-events:none;
// }

// .c-header__logo {
// 	display: none;
// 	padding: 2.8rem;
// 	@include mq($from: tablet) {
// 		display: block;

// 		svg {
// 			.logo-white {
// 				fill: white;
// 				transition: all 0.2s ease;
// 			}
// 			.logo-black {
// 				fill: black;
// 				transition: all 0.2s ease;
// 			}
// 		}
// 	}
// 	@include mq($from: desktop-wide) {
// 		padding: 2.8rem;;
// 	}
// }

// .c-header__logo-dark {
// 	display: none;
// 	@include mq($from: tablet) {
// 		display: block;
// 		padding: 0 4rem;

// 		svg {
// 			.logo-fill {
// 				fill: black;
// 				transition: all 0.8s ease;
// 			}
// 		}
// 	}
// 	@include mq($from: desktop-wide) {
// 		padding: 43px 4rem;
// 	}
// }

// .nav--open .c-header__logo svg {
// 	.logo-fill {
// 		@include mq($from: tablet) {
// 			fill: black;
// 			transition: all 0.8s ease;
// 		}
// 	}
// 	.logo-fill.logo-fill--orange {
// 		@include mq($from: tablet) {
// 			fill: #f6f6f6;
// 			transition: all 0.8s ease;
// 		}
// 	}
// }

// .c-header__nav,
// .c-header__nav__dark {
// 	display: none;
// 	position: absolute;
// 	width: 100%;
// 	z-index: 2;
// 	.c-header__nav-order:nth-child(1) {
// 		order: 1;
// 	}
// 	.c-header__nav-order:nth-child(2) {
// 		order: 2;
// 	}
// 	.c-header__nav-order:nth-child(3) {
// 		order: 3;
// 	}
// 	.c-header__nav-order:nth-child(4) {
// 		order: 4;
// 	}
// 	.c-header__nav-order:nth-child(5) {
// 		order: 6;
// 	}
// 	.c-header__nav-order:nth-child(6) {
// 		order: 7;
// 		display: flex;
// 		flex-direction: row;
// 		align-items: center;
// 		padding-left: 1%;
// 	}
// 	.c-header__nav-order:nth-child(7) {
// 		order: 9;
// 	}
// 	.c-header__nav-order:nth-child(8) {
// 		order: 9;
// 		padding-left: 3%;
// 	}
// 	.c-header__nav-order:nth-child(9) {
// 		order: 10;
// 	}
// 	.c-header__nav-order:nth-child(10) {
// 		order: 11;
// 	}

// 	@include mq($from: custom-950) {
// 		position: revert;
// 		display: flex;
// 		transition: all 0.8s ease;
// 		align-items: start;
// 		padding-top: 2%;
// 		justify-content: flex-end;
// 	}

// 	&--open {
// 		display: block;
// 	}
// }

// .nav--open .c-header__nav {
// 	@include mq($from: tablet) {
// 		transition: all 0.8s ease;
// 	}
// }

// .c-header__button {
// 	background: transparent;
// 	color: $color-white;
// 	font-family: DIN Next Pro Regular;
// 	font-size: 1rem;
// 	line-height: 1.75rem;
// 	padding: 3px 2px;
//     width: 83px;

// 	&:hover {
// 		color:$color-pink;
// 		text-decoration: underline 2px solid;
// 		text-underline-offset: 0.2rem;
// 	}
// }

// .nav--open .c-header__button {
// 	// @include mq($from: tablet) {
// 		text-underline-offset: 0.2rem;
// 		background: transparent;
// 		color: $color-white;
// 		font-family: DIN Next Pro Regular;
// 		opacity: .5;
// 		text-decoration: underline 2px;
// 		transition: all .2s ease;
// 	// }
// }

// .c-header__container {
// 	@include mq($from: tablet) {
// 		position: relative;
// 	}
// }

// .c-header__dropdown {
// 	visibility: hidden;
// 	background-color: $color-dark-grey;
//     flex: 1 1 100%;
//     flex-direction: column;
//     height: -webkit-fit-content;
//     height: -moz-fit-content;
//     height: fit-content;
//     padding-top: 0.5%;
//     position: absolute;
//     z-index: 1;

// 	// @include mq($from: tablet) {
// 	// 	flex: 1 1 100%;
// 	// 	flex-direction: column;
// 	// 	height: -webkit-fit-content;
// 	// 	height: -moz-fit-content;
// 	// 	height: fit-content;
// 	// 	z-index: 1;
// 	// 	position: absolute;
// 	// 	padding-left: 1.5%;
// 	// 	padding-top: 0.5%;
// 	// }
// 	&--opening {
// 		display: flex;
// 		visibility: visible;
// 		// @include mq($from: tablet) {
// 		// }
// 	}

// 	&--open {
// 		opacity: 1;
// 		visibility: visible;
// 		@include mq($from: tablet) {
// 			transition: all 0.8s ease;
// 		}
// 	}

// 	&--drop-down-open {
// 	}
// }

// .header-menu {
//     display: flex;
//     flex-direction: column;
//     flex-wrap: nowrap;
//     position: relative;
//     justify-content: space-evenly;
// }

// .c-nav__dropdown {
// 	height: 0;
// 	transition: all 500 ease;
// 	padding-left: 0px;
//     padding-top: 4px;
// }
.cp-nav__dropdown--open {
	visibility: visible !important;
	max-height: 50rem;
	height: auto !important;
	transition: all 500 ease;
}

// .c-header__dropdown-nav {
// 	align-items: flex-start;
//     background: rgba(51,63,71,.8);
//     /* border-top: 4px solid #f66; */
//     flex: 1 1 100%;
//     flex-direction: column;
//     padding-bottom: 7%;
//     padding-left: 7.5%;
//     padding-right: 20px;
//     width: 100%;

// }

// .c-header__dropdown-link {
// 	padding: 16px 12px 36px 9px;
// 	display: block;
// 	padding-left: 5%;

// 	@include mq($from: tablet) {
// 		color: $color-white;
// 		flex: 0 1 31%;
// 		justify-self: flex-start;
// 	}
// 	&:nth-of-type(3n) {
// 		@include mq($from: tablet) {
// 			margin-right: 0;
// 		}
// 	}
// }

// .c-header__dropdown-link:nth-last-child(-n + 3) {
// 	@include mq($from: tablet) {
// 		// border-bottom: 2px solid $color-white;
// 		margin-bottom: -2.3rem;
// 	}
// }

// .menu-button-show {
// 	display: none;
// }

// .logout-btn {
// 	width: 100%;
//     padding: 0;
//     text-align: left;
//     background: rgba(51,63,71,.8);
// 	padding: 6px 6px 23px 16px;
//     font-size: 15px;
//     border-radius: 0;

// 	&:hover {
// 		text-underline-offset: 0.2rem;
// 		color: $color-pink;
// 	}
// }

// .menu-button {
// 	width: 14vw;
// 	padding-top: 4vw;
// 	padding-right: 4vw;
// 	display: flex;

// 	@include mq($until: tablet) {
// 			width: 12rem;
// 		padding-top: 7%;
// 		padding-right: 0;
// 	}

// 	span{
// 		color: orange;
// 		display: inline-block;
// 		padding-bottom: 5%;
// 		background-image: linear-gradient(to right, orange 33%, transparent 33% 66%, orange 66%);
// 		background-position: right bottom;
// 		background-size: 300% 3.5px;
// 		background-repeat: no-repeat;

// 		&:hover {
// 			background-position: left bottom;
// 			transition: background-position 0.5s;
// 		}
// 	}
// }

// .navigation-menu-no-show {
// 	display: none;
// }

// .navigation-menu-show {
// 	display: block;
// 	transition: all 0.8s ease;
// }

// .close-button {
//     float: right;
// 	width: 14vw;
// 	padding-top: 4vw;
// 	padding-right: 4vw;
// 	display: flex;

// 	@include mq($until:tablet){
// 		float: right;
// 		width: 12rem;
// 		padding-top: 7%;
// 		padding-right: 0;
// 	}
// }

// .navigation-menu {
// 	transition: all 0.8s ease;
// 	height: 100vh;
// 	width: 100vw;
// 	position: fixed;
// 	z-index: 1;
// 	top: 0;
// 	left: 0;
// 	background-size: 100%;
// 	background-size: cover;

// 	@include mq($until:tablet){
// 		background-size: cover;
// 	}
// }

// .navigation-video {
// 	position: fixed;
// 	min-width: 100%;
// 	min-height: 100%;
// 	width: auto;
// 	height: auto;
// 	z-index: -100;
// 	background-color: rgba(30, 30, 30, 0.1);
// 	background-size: cover;
// 	transition: 1s opacity;
// }

// .nav-menu-container {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     position: absolute;
//     top: 14%;
//     width: 100%;
//     left: 26%;

// 	@media screen and (max-height: 720px){
//         height: 80%;
// 		overflow: auto;
// 		-ms-overflow-style: none;  /* IE and Edge */
//   		scrollbar-width: none;  /* Firefox */
// 		  ::-webkit-scrollbar{
// 			display: none;
// 		}
//     }

// 	@include mq($until:desktop-wide){
// 		top: 14%;
// 		width: 42%;
// 		left: 32%
// 	}

// 	@include mq(tablet, custom-850){
// 		top: 22%;
// 		width: 42%;
// 		left: 32%;
// 	}

// 	@include mq(custom-700, custom-750){
// 		left: 27%;
// 	}

// 	@include mq($until:custom-700){
// 		top: 20%;
// 		width: 90%;
// 		left: 16%;
// 	}

// 	@include mq($until:phone){
// 		top: 18%;
// 		width: 64%;
// 		left: 19%;
// 	}

// }

// .c-nav__button {
// 	font-family: "Noe Text Bold";
// 	font-size: 50px;
// 	line-height: 60px;
// 	color: $color-white;
// 	width: 100%;
// 	justify-content: space-between;
// 	padding: 0;
// 	text-align: left;

// 	@include mq($until:desktop-wide){
// 		font-size: 40px;
// 		line-height: 50px;
// 		padding: 0;
// 	}

// 	@include mq($until:phone){
// 		font-size: 25px;
// 		line-height: 30px;
// 		padding-bottom: 10%;
// 	}

// 	@include mq($from: tablet) {
// 		width: auto;
// 		position: relative;
// 		background: transparent;
// 		border: none;
// 		transition: all 0.2s ease;
// 	}

// 	&:hover {
// 		color: orange;
// 		text-decoration: underline 4px solid orange;
// 		text-underline-offset: 30%;

// 		.icon {
// 			filter: invert(58%) sepia(60%) saturate(1160%) hue-rotate(334deg) brightness(94%) contrast(88%);
// 		}
// 	}

// 	&:after {
// 		content: "";
// 		display: none;
// 	}

// 	&--open {
// 		color: orange;
// 		text-decoration: underline 4px solid orange;
// 		text-underline-offset: 30%;
// 	}

// 	.icon {
// 		pointer-events: none;
// 		height: 25px;
// 		width: 50px;
// 		@include mq($from: tablet) {
// 			transition: all 0.2s ease;
// 		}
// 	}

// 	&--open .icon {
// 		@include mq($from: tablet) {
// 			filter: invert(58%) sepia(60%) saturate(1160%) hue-rotate(334deg) brightness(94%) contrast(88%);
// 		}
// 	}
// }

// .c-nav__link {
// 	font-family: "Noe Text Bold";
// 	font-size: 50px;
// 	line-height: 60px;
// 	color: $color-white;

// 	@include mq($until:desktop-wide){
// 		font-size: 40px;
// 		line-height: 50px;
// 	}

// 	@include mq($until:phone){
// 		font-size: 25px;
// 		line-height: 30px;
// 		padding-bottom: 10%;
// 	}

// 	&:hover {
// 		color: orange;
// 		text-decoration: underline 4px solid orange;
// 		text-underline-offset: 30%;
// 	}
// }

.cp-nav__button--open {
	.icon {
		transition: all 0.2s ease;
		transform: rotate(270deg);
	}
}

.inner-nav--open .cp-nav__button--open {
	.icon {
		transition: all 0.2s ease;
		transform: rotate(270deg);
	}
	@include mq($from: tablet) {
		opacity: 1;
	}
}

// .nav-decor-left {
// 	position: relative;
// 	bottom: 890px;
// 	top: -50;
// 	z-index: -1;
// 	text-align: left;
// }

// .nav-decor-right {
// 	position: relative;
// 	bottom: 563px;
// 	left: 93px;
// 	top: -50;
// 	z-index: 0;
// 	text-align: right;
// }

// .c-nav__dropdown {
// 	visibility: hidden;
// }

.cp-nav__dropdown.cp-nav__dropdown--opening.cp-nav__dropdown--open {
	@include mq($from: tablet) {
		transition: all 500 ease;
	}
}

// .c-nav__dropdown-nav {
// 	width: 100%;
// 	flex: 1 1 100%;
// 	flex-direction: column;
// 	padding-bottom: 10%;
// 	align-items: flex-start;
// 	display: flex;
// }

// .c-nav__dropdown-link {
// 	@include mq($from: tablet) {
// 		color: $color-white;
// 		flex: 0 1 31%;
// 		justify-self: flex-start;
// 		display: block;
// 	}
// 	&:nth-of-type(3n) {
// 		@include mq($from: tablet) {
// 			margin-right: 0;
// 		}
// 	}
// }

// .c-nav__dropdown-link-text {
// 	font-family: "Untitled Sans Regular";
// 	font-size: 22px;
// 	line-height: 32px;
// 	color: $color-white;
// 	text-align: left;
// 	position: relative;
// 	@include mq($until: tablet) {
// 		font-size: 18px;
// 		line-height: 28px;
// 	}

// 	&:hover {
// 		color: orange;
// 	}

// 	&:after {
// 		content: "";
// 		position: absolute;
// 		width: 100%;
// 		transform: scaleX(0);
// 		height: 3px;
// 		bottom: -6px;
// 		left: 0;
// 		background-color: orange;
// 		transform-origin: bottom right;
// 		transition: transform 0.25s ease-out;
// 	  }

// 	&:hover:after {
// 		transform: scaleX(1);
// 		transform-origin: bottom left;
// 	  }
// }

// .c-header__dropdown-link--mobile {
// 	@include mq($from: tablet) {
// 		display: none;
// 	}
// }

// .c-header__mobile-top-bar {
// 	background-color: transparent;
// 	display: flex;
// 	width: 100%;
// 	flex: 1 1 100%;
// 	justify-content: space-between;
// 	padding: 4rem 2rem;
// 	@include mq($from: tablet) {
// 		display: none;
// 	}
// 	&--open {
// 		background-color: black;

// 		.c-header__mobile-contact {
// 			@include mq($until: tablet) {
// 				visibility: hidden;
// 			}
// 		}
// 	}
// }

// .c-header__mobile__nav__dark,
// .c-header__mobile__pinned {
// 	background-color: purple;
// }

// .c-header__mobile--open-contact {
// 	display: flex;
// 	background-color: #f6f6f6;
// 	height: 25%;
// 	color: orangewhite;
// 	font-size: 30px;
// 	line-height: 1.2;
// 	justify-content: center;
// 	align-items: center;
// 	@include mq($from: tablet) {
// 		display: none;
// 	}
// }

// .c-header__mobile-toggle {
// 	background: transparent;
// 	.icon {
// 		height: 3rem;
// 		width: 3rem;
// 		fill: orangewhite;
// 	}

// 	.icon-open {
// 		display: block;
// 	}

// 	.icon-close {
// 		display: none;
// 	}

// 	&--open {
// 		.icon-open {
// 			display: none;
// 		}
// 		.icon-close {
// 			display: block;
// 		}
// 	}
// }

// .c-header__mobile-contact {
// 	display: flex;
// 	background-color: #f6f6f6;
// 	border-radius: 3px;
// 	color: $color-white;
// 	align-items: center;
// 	padding: 0 1rem;
// }

// .c-header__mobile-contact__text {
// 	font-size: 1.6rem;
// 	font-family: "niveau-grotesk", sans-serif;
// 	color: $color-white;
// 	align-items: center;
// 	line-height: 1.6666;
// 	margin: 0;
// 	padding: 0;
// }

// .cp-page-top-section {
// 	display: flex;
// }
// .cp-page-top-section__inner {
// 	display: flex;
// 	flex-direction: column;
// 	margin: 3rem auto 10rem;

// 	h3 {
// 		font-weight: 600;
// 		margin-top: 2rem;
// 	}
// }

// .treatment-h3 {
// 	margin: 2rem auto auto auto;
// 	padding: 0 5rem;

// 	@include mq($from: desktop) {
// 		max-width: 144rem;
// 		padding: 0 19rem;
// 	}
// }

// .cp-page-top-section__tag {
// 	border-radius: 3px;
// 	color: black;
// 	display: flex;
// 	font-size: 1.6rem;
// 	height: fit-content;
// 	justify-content: center;
// 	margin: auto;

// 	padding: 0.2rem 1.8rem;
// 	background-color: rgba(30, 30, 30, 0.1);
// }

// [data-mobile-parent-id="mobile-toggle-button"] {
// 	@include mq($until: tablet) {
// 		overflow-y: scroll;
// 		height: calc(100vh - 126px);
// 	}
// }

// .c-hero__logo-container {
// 	width: 100%;
// 	padding: 0 2rem;
// 	position: absolute;
// 	top: 0;

// 	@include mq($from: desktop) {
// 		padding: 1rem 19rem;
// 	}
// }

// .c-hero__logo-image {
// 	width: 12.7rem;
// 	float: right;
// 	background-color: black;
// 	padding: 1.4rem 1rem 1.5rem 1.1rem;
// }

// .inner-menu-padding{
// 	padding: 1%;
// }

// #Go_to_Homepage-button{
// 	display: none;
//   }

//   .nav-menu-container{
// 	#Go_to_Homepage-button{
// 		@include mq($until: tablet) {
// 			display: block;
// 		}
// 	}
//   }

.nav--open {
	.cp-header-container {
		//margin-bottom: 10px;
		transition: all 1s ease-in-out;
	}
}

.cp-header {
	background: $color-light-grey;
	border-bottom: 1px solid #e3e3e3;
	position: relative;
	width: 100%;
	z-index: 30;

	&-container {
		align-items: center;
		display: flex;
		flex-direction: row;
		height: 6rem;
		justify-content: flex-start;
		margin: auto auto 1rem;
		max-width: 1625px;
		padding-left: 22%;
		transition: all 1s ease-in-out;

		@include mq($from: desktop-standard-wide) {
			padding-left: 12%;
		}

		@include mq($until: desktop-mid) {
			padding-left: 23%;
		}

		&.desktop {
			@include mq($until: desktop) {
				display: none;
			}
		}
	}

	&-logo {
		left: 6rem;
		position: absolute;
		top: 1rem;
		width: 161px;

		&-img {
			height: 5rem;
			width: 100%;
		}
	}

	&-links-container {
		align-items: center;
		column-gap: 1rem;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		position: relative;
		width: 100%;
		margin-top: 1rem;
	}

	&__button {
		color: $color-black;
		text-align: center;
		background:transparent;

		&-menu {
			text-align: center;
			transition: 1s;
			background:transparent;
			
			&:hover {
				text-underline-offset: 5px;
				text-decoration: underline 3px $color-orange;
				transition: 1s;
				-webkit-text-decoration: underline;
				-webkit-text-decoration-color:$color-orange;
			}
			h4 {
				pointer-events: none;
			}

			&.cp-header__button--open {
				h4 {
					text-underline-offset: 5px;
					text-decoration: underline 3px $color-orange;
					transition: 1s;
					-webkit-text-decoration: underline;
				-webkit-text-decoration-color:$color-orange;
				}
			}
		}
	}

	&-sign-in {
		position: absolute;
		right: 1rem;
	}

	&__dropdown {
		left: 0.5rem;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		top: 2.6rem;
		transition: all 1s ease-in-out;

		&-nav {
			column-gap: 1rem;
			display: flex;
			flex-direction: row;
			opacity: 1;
			width: 100%;
		}

		&-link {
			display: block;

			&-text {
				color: $color-black;

				&:hover {
					color: $color-orange;
				}
			}
		}

		&--opening {
			display: flex;
			transition: all 500 ease;
		}

		&--open {
			opacity: 1;
			pointer-events: all;
			transition: all 1s ease-in;
		}
	}

	&-btn {
		h3 {
			color: $color-black;

			&:hover {
				color: $color-darkest-grey;
			}
		}
	}

	&__link {
		h3 {
			color: $color-black;
		}

		&:hover {
			h3 {
				color: $color-darkest-grey;
			}
		}
	}
}

a.cp-header__link {
	column-gap: 10px;
	display: flex;
	align-items: center;
	color: $color-black;
	background:transparent;
}

.cp-header-login {
	font-size: 18px;
}

a.obashi-btn {
	font-family: DIN Next Pro Regular;
	background:transparent;
	h3 {
		font-size: 18px;
	}

	&:hover {
		h3.get-started-nav {
			text-decoration: none;
			transition: none;
			text-underline-offset: none;
		}
	}

	&.get-started-btn {
		column-gap: 13px;
		display: flex;
		flex-direction: row;
		align-items: flex-end;

		.bi-arrow-right {
			&::before {
				font-size: 20px;
				vertical-align: -0.25em;
			}
		}
	}
}

.cp-header-container.mobile {

	@include mq($from:desktop){
		display: none;
	}
	padding-left: 0;
	transition: none;

	.cp-header {
		&-logo {
			left: 1rem;
			position: absolute;
			top: 1rem;
			width: 161px;

			&-img {
				height: 5rem;
				width: 100%;
			}
		}

		&__button-menu {
		    column-gap: 20px;
			padding: 0;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			background:transparent;

			&__open {
				color:$color-orange;
				text-underline-offset: 5px;
				text-decoration: underline 3px $color-orange;
				text-decoration: underline;
				text-decoration-color: $color-orange;
				text-decoration-thickness: 2px;
				text-decoration-style: solid;
			}
		}

		&__button {
			padding: 0;
		}

		&-chevron {
			&-up {
				content: "";
				background: url("/src/img/icons/chevron.svg") no-repeat;
				display: inline-block;
				height: 10px;
				width: 17px;
			}

			&-down {
				content: "";
				background: url("/src/img/icons/chevron-down.svg") no-repeat;
				display: inline-block;
				height: 10px;
				width: 17px;
			}
		}

		&-links-container {
			position: absolute;
			text-align: 0;
			top: 120px;
			align-items: flex-start;
			row-gap: 40px;
			display: flex;
			flex-direction: column;
			padding: 5%;
			width: 100%;
		}

		&-hamburger {
			position: absolute;
			width: 30px;
			height: 30px;
			top: 34px;
			right: 7%;
			background-color: transparent;
		}

		&__dropdown {
			position: relative;
			left: 0;
			top: 0;
			opacity: 1;
			margin-top: 20px;
			pointer-events: all;
			.cp-header__dropdown-nav {
				row-gap: 20px;
				margin-top: 15px;
				display: flex;
				flex-direction: column;
				opacity: 1;
				width: 100%;
				align-items: flex-start;
			}
		}
	}

	.header-hidden {
		display: none;
	}

}


.cp-header-sign-in.mobile {
	position: relative;
	right: 0;
	

	.sign-in-container {
		display: flex;
		row-gap: 30px;
		flex-direction: column;
		align-items: flex-start;
	}
}

.full-body {
	height: 100vh;
}

hr.mobile-nav {
    border: 1px solid $color-orange;
	width: 100%;
	opacity: 1;
	margin: 0;
	padding: 0;
	height: 0;
}