.el-pricing-item {
	align-items: center;
	background: $color-light-grey;
	border: 2px solid rgba(235, 135, 70, 0.1);
	border-radius: 20px;
	-webkit-box-shadow: -1px -1px 11px 1px rgba(29, 29, 29, 0.19);
	-moz-box-shadow: -1px -1px 11px 1px rgba(29, 29, 29, 0.19);
	box-shadow: -1px -1px 11px 1px rgba(29, 29, 29, 0.19);
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	min-width: 31%;
	position: relative;
	row-gap: 30px;
	transition: 0.5s;

	&:first-child {
		padding-bottom: 50px;
	}

	&:nth-child(2) {
		padding-bottom: 45px;
	}

	&:nth-child(3) {
		padding-bottom: 40px;
	}

	@include mq($until: desktop) {
		width: 90%;
	}

	@include mq($until: tablet) {
		width: 54%;
	}

	&:hover {
		-webkit-box-shadow: -1px -1px 11px 4px rgba(29, 29, 29, 0.19);
		-moz-box-shadow: -1px -1px 11px 4px rgba(29, 29, 29, 0.19);
		box-shadow: -1px -1px 11px 4px rgba(29, 29, 29, 0.19);
		transition: 0.5s;
		border: 2px solid rgba(235, 135, 70, 0.5);
	}

	&-buy-now {
		font-family: "DIN Next Pro Light";
		font-size: 18px;
		font-style: normal;
		line-height: normal;
		text-decoration: none;
	}

	&-top-container {
		align-items: center;
		display: flex;
		flex-direction: column;
		padding: 21px 0 0;
		row-gap: 9px;
		width: 100%;
		background: $color-orange;
		border-radius: 15px 15px 0 0;
		border: $color-orange;
	}

	&-mid-container {
		display: flex;
		flex-direction: column;
		padding: 20px 46px 0 57px;
		row-gap: 18px;
		width: 100%;

		@include mq($until: desktop) {
			padding: 0 36px;
		}
	}

	&-bottom-container {
		column-gap: 35px;
		display: flex;
		justify-content: space-around;
		padding-top: 7px;
		text-align: -webkit-center;
		text-align: center;
		width: 100%;
	}

	&-price {
		&.enterprise {
			margin-bottom: 12px;
			font-size: 40px;
		}

		&.monthly {
			display: none;
		}

		&-container {
			display: flex;
			align-items: center;
			column-gap: 1px;
			color: white;

			sup {
				width: 6px;
				height: 5px;
				font-size: 16px;
				top: -12px;
			}
		}

		&-billed {
			margin-left: 5px;
			width: 60px;
		}
	}

	&-title {
		color: $color-white;

		@include mq($until: desktop) {
			width: 92%;
			text-align: center;
		}
	}

	&-user-amount {
		padding-bottom: 14px;
	}

	&-features {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		row-gap: 15px;
		width: 100%;
		margin-left: 0;
	}

	&-feature {
		display: flex;

		align-items: center;
		justify-content: space-between;
		column-gap: 10px;

		.bi-check-lg {
			color: darkseagreen;
		}

		.bi-x-lg {
			color: red;
		}
	}

	&-cta {
		a {
			font-size: 18px;
		}

		&.enterprise {
			opacity: 1;
			margin: 0;
		}
	}

	&-hr {
		height: 1px;
		border: 0;
		border-top: 1px solid $color-orange;
		margin: 0;
		width: 100%;
	}
}

.pricing-form {
	&.yearly {
		@include mq($until: desktop) {
			left: 20px;
		}
	}

	&.monthly {
		@include mq($until: desktop) {
			left: 30px;
		}
	}

	&:hover {
		p {
			color: $color-white;
		}
	}
}
