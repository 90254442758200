.large-text {
	text-align: center;
	font-family: "DIN Next Pro Bold";
	font-size: 9.969rem;
	font-style: normal;
	line-height: normal;

	@include mq($until: desktop) {
		font-size: 8.313rem;
	}

	@include mq($until: tablet) {
		font-size: 6.625rem;
	}
}
h1 {
	font-family: "DIN Next Pro Bold";
	text-align: center;
	font-style: normal;
	line-height: normal;
	font-size: 4.209rem;

	@include mq($until: desktop) {
		font-size: 3.209rem;
	}
}

h2 {
	font-family: "DIN Next Pro Bold";
	font-style: normal;
	line-height: normal;
	font-size: 3.157rem;
}

h3 {
	font-family: "DIN Next Pro Regular";
	font-style: normal;
	line-height: normal;
	font-size: 2.02rem;
}

h4 {
	font-family: "DIN Next Pro Regular";
	font-style: normal;
	line-height: normal;
	font-size: 1.777rem;
}

h5 {
	font-size: 1.333rem;
}

p {
	font-family: "DIN Next Pro Light";
	font-size: 1.2rem;
	font-style: normal;
	line-height: normal;

	&.bold-paragraph {
		font-family: "DIN Next Pro Regular";
	}
}

span.bold-paragraph {
	font-family: "DIN Next Pro Regular";
}

button,
.button {
	font-family: "DIN Next Pro Light";
	font-size: 1.2rem;
	font-style: normal;
	line-height: normal;
	border: none;

	p {
		color: $color-black;
	}

	&:hover {
		p {
			color: $color-white;
		}
	}
}

.obashi-account-btn {
	font-family: "DIN Next Pro Regular";
	background-color: inherit;
	padding: 12px 14px;
	text-align: left;
	width: 100%;
	border-radius: 0;
	font-size: 1.2rem;
	font-style: normal;
	line-height: normal;

	&:hover {
		color: $color-black;
		text-decoration: underline 2px solid $color-orange;
		text-underline-offset: 3px;

		.bi {
			color: $color-orange;
		}

		&.btn-active {
			background-color: $color-orange;
			color: $color-white;
			border-radius: 0;

			.bi {
				color: $color-white;
			}
		}
	}

	&:focus-visible {
		border-color: $color-orange !important;
		background-color: $color-orange !important;
		border-radius: 0;

		@include mq($until: desktop) {
			border: none;
		}
	}

	&.btn-active {
		background-color: $color-orange;
		color: $color-white;
		border-radius: 0;
	}

	@include mq($until: desktop) {
		padding: 20px;

		&:hover {
			color: $color-black;
			text-decoration: none;
			text-underline-offset: none;

			.bi {
				color: $color-black;
			}
		}
	}
}

.obashi-account-btn-alt {
	font-family: "DIN Next Pro Regular";
	background-color: $color-white;
	padding: 12px 14px;
	text-align: left;
	width: 100%;
	border:2px solid $color-orange;
	font-size: 1.2rem;
	font-style: normal;
	line-height: normal;

	&:hover {
		color: $color-black;
		background:$color-white;
		text-decoration: underline 2px solid $color-orange;
		text-underline-offset: 3px;
		border:2px solid $color-orange;

		.bi {
			color: $color-orange;
		}

		&.btn-active {
			background-color: $color-orange;
			color: $color-white;

			.bi {
				color: $color-white;
			}
		}
	}

	&:focus-visible {
		border-color: $color-orange !important;
		background-color: $color-orange !important;
		border-radius: 0;

		@include mq($until: desktop) {
			border: none;
		}
	}

	&.btn-active {
		background-color: $color-orange;
		color: $color-white;
		border-radius: 0;
	}

	@include mq($until: desktop) {
		padding: 20px;

		&:hover {
			color: $color-black;
			text-decoration: none;
			text-underline-offset: none;

			.bi {
				color: $color-black;
			}
		}
	}
}

.obashi-btn,
.obashi-btn.banner {
	font-family: "DIN Next Pro Regular";
	background-color: inherit;
	border-color: $color-orange;
	transition: 1s;
	border: 2px solid $color-orange;
	--bs-btn-padding-x: 25px;
	--bs-btn-padding-y: 10px;
	border-radius: 7px;

	&.no-arrow {
		&:after {
			content: "";
		}
	}

	a,
	p {
		font-family: "DIN Next Pro Regular";
		color: $color-black;
		transition: 1s;

		&:active {
			color: $color-white !important;
		}

		&:focus-visible {
			color: $color-white !important;
		}
	}

	&:hover {
		background-color: $color-orange;
		transition: 1s;
		color: $color-white;

		a {
			transition: 1s;
			color: $color-white;
		}
	}

	&:active {
		border-color: $color-orange !important;
		background-color: $color-orange !important;
		color: $color-white !important;
	}

	&:focus-visible {
		border-color: $color-orange !important;
		background-color: $color-orange !important;
	}

	&-secondary {
		font-family: "DIN Next Pro Regular" !important;
		font-size: 1.2rem;
		background-color: $color-orange;
		border-color: $color-orange;
		transition: 1s;
		color: $color-white;
		border: 2px solid $color-orange;
		--bs-btn-padding-x: 25px;
		--bs-btn-padding-y:10px;
		border-radius: 7px;
		margin-top: 0;

		&:hover {
			background-color: $color-orange-secondary;
			transition: 1s;
			border-color: $color-orange-secondary;
			color: $color-white;
		}

		&:active {
			border-color: $color-orange !important;
			background-color: $color-orange !important;
			color: $color-white !important;
		}

		&:focus-visible {
			border-color: $color-orange !important;
			background-color: $color-orange !important;
		}
	}

	&.delete-account-btn {
		border: red 2px solid;
		color: red;
		transition: 1s;

		&:hover {
			background-color: red;
			color: $color-white;
			transition: 1s;
		}
	}

	&.banner {
		background-color: $color-white;
		color: $color-black;

		&:hover {
			background-color: $color-white;
			transition: 1s;
			color: $color-orange;

			a {
				transition: 1s;
				color: $color-orange;
			}
		}
	}
}

.validation {
	font-family: "DIN Next Pro Regular";
	font-size: 0.75rem;
	font-style: normal;
	line-height: normal;
}

.sub-menu {
	font-family: "DIN Next Pro Regular";
	font-size: 0.75rem;
	font-style: normal;
	line-height: normal;
}

.uppercase {
	text-transform: uppercase;
}

table {
	border-color: $color-orange;
	border-width: 1px;
}

td {
	border-width: 1px;
	border-color: $color-orange;
}

a.obashi-link {
	width: 100%;
	&:focus-visible {
		outline: $color-orange auto 1px !important;
		border-color: $color-orange !important;
	}
}

a {
	font-family: "DIN Next Pro Regular";
	font-size: 1rem;
	font-style: normal;
	line-height: normal;
	text-decoration: none;
	transition: 1s;
	color: $color-orange;
	&:hover {
		h1,
		h1,
		h3,
		h4,
		p {
			
			transition: 1s;
			text-underline-offset: 5px;
			text-decoration: underline;
			text-decoration-style: solid;
			text-decoration-color: $color-orange;
			text-decoration-thickness: 3px!important;
		}

		color: $color-dark-grey;
	}
}

li {
	font-family: "DIN Next Pro Light";
	font-size: 1rem;
	list-style-position: outside;
	margin-bottom: 0.5rem;
	margin-left: 1rem;
}

.form-check-input:checked {
	background-color: $color-orange;
	border-color: $color-orange;
}

.form-check-input:focus {
	box-shadow: 0 0 0 0.25rem rgba(235, 135, 70, 0.5);
}

:not(.btn-check) + .btn:active {
	border-color: $color-orange;
}
