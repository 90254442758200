.no-scroll {
	overflow: hidden;
}

.l-flex {
	display: flex;
}

.l-grid {
	display: grid;
}

.l-inlineblock {
	display: inline-block;
	vertical-align: middle;
}

.l--block {
	display: block;
}

.l--maxwidth {
	max-width: 27px;
}

.l-maxWidth--1065 {
	max-width: 1065px;
	margin: 0 auto;
}

.l-maxWidth--790 {
	max-width: 790px;
	margin: 0 auto;
}

.l--sectionPadding {
	padding: 0 15px;

	@include mq($until: custom-950) {
		padding: 0 35px;
	}

	@include mq($until: phablet) {
		padding: 0 15px;
	}
}

.l--genericBorder {
	border: 2px solid
}

.l--genericBorderRadiusSmall {
	border-radius: 4px;
}

.l--genericBorderRadius {
	border-radius: 8px;
}

.l--genericBorderRadiusMedium {
	border-radius: 12px;
}

.l--genericBorderRadiusLarge {
	border-radius: 20px;
}

a,
picture {
	display: block;
}

.l-relative {
	position: relative;
}

.l-absolute {
	position: absolute;
}


.l-toggle {
	opacity: 0;
	visibility: hidden;
	transform: translate(0, 20px);
	max-height: 0;
	width: 0;
	transition: none;

	&.is-active {
		opacity: 1;
		visibility: visible;
		transform: translate(0, 0);
		max-height: 5000px;
		width: auto;
		transition: transform 0.5s ease-in-out, max-height 0.5s ease-in-out, opacity 0.5s ease-in-out 0.1s, margin 0.5s ease-in-out;
	}
}

.l--transform {
	transform: translateY(-100px);
}

.l-hidden {
	display: none;

	&.is-active {
		display: block;
	}
}

#main {
	overflow: hidden;
	.inner-for-transition{
		display: none;
	}
	.inner-for-transition:last-child{
		display: block;
	}
}


select::-ms-expand {
	display: none;
}
