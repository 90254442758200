.cp-billboard {
	position: relative;

	&-background {
		width: 100%;
	}

	@include mq($until: desktop) {
		position: relative;
		width: 100%;
		height: unset;
		min-height: unset;
		max-height: unset;

		@include mq($until:tablet){
			padding: 0;
		}
	}

	&-container {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
	    padding: 7.3%;
		position: absolute;
		row-gap: 28px;
		top: 0;
		width: 100%;
		z-index: 10;

		@include mq($from:desktop-standard-wide){
			padding: 18.3%;
		}

		@include mq($until:desktop){
			padding: 77px;	
			justify-content: center;
			row-gap: 20px;
		}

		@include mq($until: tablet) {
			padding: 25px;
		}
	}

	&-gradient {
		background: rgba(0,0,0,1);
		height: 100%;
		left: 0;
		opacity: .5;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
	}

	&.orange {
		background-color: $color-orange;
	}

	&-title {
		@include mq($until: desktop) {
			text-align: center;
		}

		@include mq($until: tablet) {
		}
	}

	&-subtitle {
		p {
			color: $color-white;
			width: 52%;
			font-size: 23px;

			@include mq($until:desktop){
				width: 80%;
			}

			@include mq($until:tablet){
				width: 100%;
			}
		}
	}

	&-btn {

		background-color: $color-orange;

		a {
			color:$color-white;
		}

		&:hover {
			background-color: $color-orange-secondary;
		}

		&.orange {
			background-color: $color-white;
			&:hover {
				background-color: $color-white;
				border: 2px solid $color-orange;

				a {
					color: $color-orange;
				}
			}
		}
	}
}

.cp-short-billboard {
	background-color: $color-orange;
    border-radius: 15px;
    margin: 55px auto 65px;
    max-width: 1200px;
    width: 71%;

	@include mq($until:desktop){
		width: 74%;
	}

	@include mq($until:tablet){
		width: 86%;
	}

	&-container {
	    align-items: center;
		display: flex;
		flex-direction: row;
		height: 100%;
		justify-content: space-between;
		padding: 60px 51px;
		position: relative;
		row-gap: 0;
		width: 100%;
		z-index: 1;

		@include mq($from:tablet, $until:desktop){
			padding: 32px 40px;
		}

		@include mq($until:tablet){
			flex-direction: column;
			align-items: center;
			padding: 38px 40px;
			row-gap: 27px;
		
		}
	}

	&-text-container {
		display: flex;
		row-gap: 10px;
		flex-direction: column;
		align-items: flex-start;
		width: 63%;

		@include mq($until:tablet){
			width: 100%;
		}
	}

	&-subtitle {
		color: $color-white;
		font-size: 24px;
	}

	&-title {
		color: $color-white;
		font-size: 3.02rem;
	}

	&-title, &-subtitle {
		@include mq($until:tablet){
			text-align: center;
		}
	}

	&-btn {
		background-color: $color-white;    
		--bs-btn-padding-x: 30px;
		--bs-btn-padding-y: 11px;

		&:hover {
			background-color: $color-white;
			border: 2px solid $color-orange-secondary;

			a {
				color: $color-orange;
			}
		}

		&:active {
			background-color: $color-white!important;
			a {
				color:$color-orange!important;
			}
		
		}
	}
}
