/*------------------------------------*\
  #SHARED
\*------------------------------------*/

/**
 * Shared declarations for certain elements.
 */

/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */

  body {
    color: $color-white;
    margin: 0;
    padding: 0;
  }

  .visuallyhidden {
    order: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    background: transparent;
}

//skip to content focus
.skiplist {
    margin: 0 auto;
    padding: 0;
    position: fixed;
    left: -9999px;
    top: 0;
    background-color: #000;
    max-width: 1920px;
    z-index: 100000;
    padding: 0;
    color: $color-white;
    text-decoration: none;
    padding: 10px 20px;
}

.skiplist:focus, .skiplist:active {
    left: 0;
    top: 30px;
}

@include mq($until: tablet-wide) {
    .skiplist {
        display: none;
    }
}

/* ==========================================================================
   UTILITIES
   ========================================================================== */

// html {
//     font-size: 1em;
//     line-height: 1.4;
// }

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection rule sets have to be separate.
 * Custo∂mize the background color to match your design.
 */

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

/*
* A better looking default horizontal rule
*/

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

/*
* Remove the gap between images, videos, audio and canvas and the bottom of
* their containers: h5bp.com/i/440
*/

audio,
canvas,
img,
video,
svg {
    vertical-align: middle;
}

/*
* Remove default fieldset styles.
*/

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

/*
* Allow only vertical resizing of textareas.
*/

textarea {
    resize: vertical;
}

img {
    max-width: 100%;
}

/*
* No top margin on all elements
*/

* {
    margin-top: 0;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

// /* layout & grid overrides */

// ul {
//   padding-left: 18px;

//   li {
//     margin-bottom: 1em;
//   }
// }

dd {
    margin: 0;
}

.cp-page-header {
    padding: 0.75rem 1.5rem;
    background-image: radial-gradient(circle farthest-corner at -120px 20px,#f00001,#d30404,#fc5261,#fb7c5f,#f1ac30);
    color: $color-white;
}

input[type="text"]{
    font-family: DIN Next Pro Regular;
    font-size: 16px;
}

input[type='password']{
    font-family: Verdana;
    letter-spacing: 0.1em;
}

[type=checkbox], [type=radio] {
    accent-color: $color-orange;
}

td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.form-floating > label {
    font-family: "DIN Next Pro Regular";
}