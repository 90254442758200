.collapse {
    &:not(.show) {
      display: none;
    }
  }
  
  .collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease;
  
    &.collapse-horizontal {
      width: 0;
      height: auto;
      transition: width .35s ease;
    }
  }