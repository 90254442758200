.el-feature-item {
    color:$color-white;
    &-header {
        font-family: DIN Next Pro Bold;
        font-size: 18px;
        margin-bottom: 0.5rem;

        &-container {
            align-items: baseline;
            column-gap: 0.5rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

        
			@include mq($from:tablet, $until:desktop){
                flex-direction: column;
                justify-content: flex-start;
			}
        }
    }

    &-body {
        font-family: DIN Next Pro Light;
        font-size: 16px;
        margin: 0rem 1.3rem;
        margin-right: 4rem;

        @include mq($until:tablet-wide){
            margin: 0;
            width: 89%;
        }
    }

    &-locked {
        height: 15px;
        width: 15px;
        fill:$color-orange;

        &-container {
            display: flex;
            column-gap: 10px;

            @include mq($until:tablet-wide){
                column-gap: 6px;
            }
        }
    }
    
    &-version {
        font-size:13px;
        font-family: "DIN Next Pro Regular";
    }

}