.cp-banner {
    padding: 1rem;

    &-container {
        justify-content: center;
        width: 100%;
        align-self: center;
        display: flex;
        align-content: center;
        align-items: center;
        column-gap: 4%;
    }
}