.cp-anchor {
    width: 100%;
    padding: 1.8rem 8rem 1.5em;
    border-bottom: 1px solid #ccc;
    background-color: $color-white;
    z-index: 10000;

    @include mq($until:tablet){
        justify-content: space-between;
        padding: 15px 25px;
        column-gap: 0;
    }

    &-container {
        column-gap: 10rem;
        display: flex;
        justify-content: space-evenly;
        margin: auto;
        max-width: 1612px;
    }
}

.el-anchor-links {
    a {
        scroll-behavior: smooth;
        text-decoration: none;
        color:$color-black;
        transition: 1s;
        
        &:hover {
            color:$color-orange;
            transition: 1s;
        }
    }
}

.anchor-item {
    scroll-margin-top: 120px;
}