.cp-trusted-carousel {
    align-items: center;
    display: flex;
    flex-direction: column;
	padding: 2% 0 4%;
    row-gap: 15px;

	@include mq($until:tablet){
		padding: 7% 25px 7%;
	}

	&-title {
		text-align: center;
	}

	&-container {
		.splide__list {
			align-items: center;
		}
	}

	&-logos {
		.splide__slide{
			opacity:1!important;
		}
	}

	&-images {
		margin:0;
		width: 85.3%;
		margin: auto;
		max-width: 1612px;

		@include mq($until:tablet){
			width: unset;
			margin:unset
		}
		
		.swiper-wrapper {
			align-items: center!important;
			.swiper-slide {
				img {
					width: 100%;
				}
			}
		}
	}

	&-slider {
		&-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
	}
}
