.umbraco-forms-field {
	input, select, textarea {
		font-family: DIN Next Pro Regular;
		font-size: 16px;
	}

	textarea {
		height: 250px!important;
		resize: none;
	}
}

.umbraco-forms-container.form-row {
	display: flex;
    flex-direction: column;
    row-gap: 15px;
    align-items: stretch;
}

.umbraco-forms-navigation {
    margin-top: 30px;

	input {

		@include mq($until:desktop){
				width: 100%;
		}
	
	}
}

.contact-page-required {
	font-family: "DIN Next Pro Regular";
	padding-top: 10px;
}