.account-page {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-end;
	margin: 4.3% 7.3% 7%;

	&-container {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;

		hr {
			width: 100%;
			margin-top: 0;
			opacity: 1;
			border-top: 2px solid $color-orange;

			@include mq($until: tablet-wide) {
				padding-bottom: 10px;
			}
		}

		hr.other-hr {
			border-top: 2px solid $color-dark-grey;
			margin: 30px auto;
			margin-top: 20px;
			opacity: 0.5;
			width: 93%;
		}
	}

	&-content {
		&-container {
			&.desktop {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				justify-content: space-between;
				width: 100%;
				max-width: 1612px;
				margin: auto;

				@include mq($until: tablet-wide) {
					display: none;
				}
			}

			&.mobile {
				width: 100%;

				@include mq($from: tablet-wide) {
					display: none;
				}
			}
		}
	}

	&-nav {
		align-items: flex-start;
		row-gap: 10px;
		display: flex;
		flex-direction: column;
		width: 23.1%;

		@include mq($until: tablet-wide) {
			width: 100%;
		}

		&-inner {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			background: $color-lightest-grey;
			border-radius: 5px;
			width: 100%;

			.obashi-account-btn {
				display: flex;
				flex-direction: row;
				align-items: center;
				column-gap: 15px;
				justify-content: flex-start;

				&:first-child {
					border-radius: 5px 5px 0 0;
				}
			}

			.obashi-account-btn-alt {
				display: flex;
				flex-direction: row;
				align-items: center;
				column-gap: 15px;
				justify-content: flex-start;
				border-radius: 5px 5px 0px 0px;
			}
		}

		form {
			width: 100%;
		}
	}

	&-logout {
		margin-top: 20px;
		width: 100%;
		font-size: 17px;
	}

	&-top-container {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		max-width: 1612px;
		margin: auto;

		@include mq($until: tablet-wide) {
			flex-direction: column;

			h1 {
				text-align: left;
			}
		}
	}

	&-details {
		width: 68.3%;

		&-inner {
			background: $color-lightest-grey;
			width: 100%;
			border-radius: 5px;
		}
	}

	&-tab {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		row-gap: 0px;
		border-radius: 5px;
		padding-bottom: 25px;

		@include mq($until: tablet-wide) {
			background: $color-white;
			padding-top: 10px;
			border-radius: 0;
			padding: 20px 3.6%;
		}

		&-heading-container {
			background: $color-orange;
			width: 100%;
			height: 100%;
			border-radius: 5px 5px 0 0;
			padding: 28px 27px;
			color: $color-white;
			margin-bottom: 20px;
		}
	}

	&-user-details {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		row-gap: 15px;
		margin-bottom: 20px;

		.page-details {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			@include mq($until: tablet-wide) {
				row-gap: 10px;
			}

			&.subscription {
				flex-direction: row;

				@include mq($until: tablet-wide) {
					flex-direction: column;
				}

				.input-group {
					&:hover {
						background-color: transparent;
					}

					cursor: unset;
				}
			}

			.input-group {
				align-items: center;
				border-radius: 3px;
				column-gap: 15px;
				flex-direction: row;
				width: 100%;

				cursor: pointer;

				.input-icon {
					padding-left: 30px;

					@include mq($until: tablet-wide) {
						padding-left: 15px;
					}
				}

				.bi-chevron-right {
					padding-right: 30px;

					@include mq($until: tablet-wide) {
						padding-right: 15px;
					}
				}

				&:hover {
					background-color: rgba(29, 29, 29, 0.2);
				}

				&-container {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					padding: 1% 0;

					.input-group-text {
						font-family: "DIN Next Pro Bold";
						background: transparent;
						border: 0;
						color: $color-black;
						font-size: 18px;
						padding: 1% 0;
						width: 100%;
						padding: 0;
					}

					.account-detail {
						font-family: "DIN Next Pro Regular";
						color: $color-dark-grey;
						font-size: 18px;
						padding: 5px 0px;
						user-select: none;
						-webkit-user-select: none;
						-ms-user-select: none;
						width: 100%;

						@include mq($until: tablet-wide) {
							width: 88%;
						}
					}
				}
			}
		}
	}

	&-update-modal {
		.form-row {
			row-gap: 15px;
			display: flex;
			flex-direction: column;
			align-items: stretch;
			padding-bottom: 20px;
			padding: 20px 20px;
		}

		.modal-body {
			padding: 0;
		}

		.modal-footer {
			width: 100%;
			padding: 15px 20px;
			align-items: stretch;

			.obashi-btn {
				padding: 7px 25px;
			}

			.obashi-btn-secondary {
				--bs-btn-padding-x: 25px;
				--bs-btn-padding-y: 7px;
			}
		}

		input,
		select {
			font-family: inherit;
			font-size: 15px;
		}
	}

	&-subscriptions-btn {
		padding: 0 3.6%;
		margin-top: 20px;

		button {
			font-size: 17px;
			width: 100%;
		}
	}

	&-security-btns {
		align-items: center;
		column-gap: 50px;
		display: flex;
		margin-bottom: 25px;
		margin-top: 10px;

		@include mq($until: tablet) {
			align-items: flex-start;
			margin-bottom: 35px;
			flex-direction: column;
			row-gap: 10px;
		}
	}

	&-downloads {
		padding: 0 3.6%;
		.downloads-top-container {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			row-gap: 10px;

			.btn-group {
				row-gap: 20px;
				flex-direction: column;
			}

		
		}

		.downloads-bottom-container {
			width: 100%;
			display: flex;
			row-gap: 8px;
			flex-direction: column;
			align-items: flex-start;


			.btn-group {
				column-gap: 40px;

				a {
					width: 30%!important;
				}

				form {
					width: 30%!important;
				}
			}


			.file-download-container {
				a {
					width: 48%;

					@include mq($until:tablet){
						width: 100%;
					}
				}
			}

			.obashi-btn {
				&:hover {
					.docker {
						&::before {
							background: url("/src/img/icons/docker-white.svg");
							background-repeat: round;
							transition: 1s;
						}
					}
				}
			}
		}

		.downloads-bottom-container,
		.downloads-top-container {
			.btn-group {
				width: 100%;

				a {
					width: 50%;

					@include mq($until:tablet){
						width: 100%;
					}

					.obashi-btn {
						width: 100%;
					}
				}

				form {
					width: 50%;

					.obashi-btn {
						width: 100%;
					}
				}
			}

			a {
				@include mq($until:tablet){
					width: 100%;
				}
			}
		}

		.page-details {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			row-gap: 40px;
			padding: 10px 0;
		}

		.form-text {
			width: 67%;
			margin-bottom: 15px;
			color: $color-black;
			font-size: 15px;
			font-family: "DIN Next Pro Regular";

			@include mq($until: tablet) {
				width: 100%;
			}
		}

		.obashi-btn {
			display: flex;
			column-gap: 5px;
			align-items: center;
			justify-content: center;

			@include mq($until: tablet-wide) {
				width: 70%;
			}

			@include mq($until:tablet){
				width: 100%;
			}
		}

		.docker {
			width: 24px;
			height: 24px;
			&::before {
				background: url("/src/img/icons/docker.svg");
				content: "";
				height: 100%;
				width: 100%;
				background-repeat: round;
				display: inline-block;
				transition: 1s;
			}
		}
	}

	&-get-started {
		padding: 12px 3.6%;
		padding-bottom: 40px;
		&-container {
			display: flex;
			row-gap: 40px;
			flex-direction: column;
			align-items: flex-start;
		}

		.get-started-steps {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: 100%;
			row-gap: 15px;

			a {
				@include mq($until:tablet){
					width: 100%;
				}
			}
		}

		.step-btn {
			.obashi-btn {
				width: 100%;
				.docker {
					width: 24px;
					height: 24px;
					&::before {
						background: url("/src/img/icons/docker.svg");
						content: "";
						height: 100%;
						width: 100%;
						background-repeat: round;
						display: inline-block;
						transition: 1s;
					}
				}

				&:hover {
					.docker {
						&::before {
							background: url("/src/img/icons/docker-white.svg");
							background-repeat: round;
							transition: 1s;
						}
					}
				}
			}

			.docker-btn {
				align-items: center;
				column-gap: 15px;
				display: flex;
				justify-content: flex-start;
			}
		}

		.step {
			&-btn {
				width: 25%;
				display: flex;
				column-gap: 10px;
				flex-direction: row;
				align-items: center;
				justify-content: center;

				@include mq($from: desktop-standard-wide) {
					width: 20%;
				}

				@include mq($until: desktop-wider) {
					width: 35%;
				}

				@include mq($until: tablet) {
					width: 100%;
				}
			}

			&-title {
				width: 70%;

				@include mq($until: desktop-wider) {
					width: 90%;
				}

				@include mq($until: tablet) {
					width: 100%;
				}
			}

			&-subtitle {
				width: 75%;

				@include mq($until: desktop-wider) {
					width: 90%;
				}
			}
		}

		.multiple-btns {
			row-gap: 10px;
			display: flex;
			width: 100%;
			flex-direction: row;
			justify-content: flex-start;
			column-gap: 25px;

			@include mq($until: tablet) {
				flex-direction: column;
			}
		}
	}
}

.p-step-btn {
	align-items: center;
	column-gap: 15px;
	display: flex;
	justify-content: flex-start;
}


.account-page-file-downloads {
	width: 100%;
	height: 100%;
}

.file-download-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: wrap;
	column-gap: 25px;
	row-gap: 30px;

	@include mq($until: tablet) {
		flex-direction: column;
		justify-content: flex-end;
		row-gap: 15px;
	}
}

.account-page-hide {
	display: none;
}

.update-name-form {
	align-items: stretch;
	display: flex;
	flex-direction: column;
	row-gap: 15px;

	.form-row {
		row-gap: 17px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

		.col {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			row-gap: 4px;
			width: 100%;
		}
	}
}

.modal-footer {
	column-gap: 10px;
	.obashi-btn {
		margin-top: 0;
		width: unset;
	}
}

.modal-body {
	padding: 0;
}

.account-page-security-container {
	display: flex;
	column-gap: 10px;
	padding: 0 3.6%;
}

.checkbox-container {
	display: flex;
	flex-direction: row;
	column-gap: 10px;
	align-items: center;
	padding: 8px 0px 20px;

	.form-check {
		display: flex;
		column-gap: 14px;
		align-items: center;
	}

	input.form-check-input {
		border: 2px solid black;
		width: 1.2em;
		height: 1.2em;
		margin-top: 0;
	}
}

.security-bottom-container {
	display: flex;
	row-gap: 5px;
	flex-direction: column;
	align-items: flex-start;

	.delete-account-subtitle {
		align-items: center;
		display: flex;
		font-family: DIN Next Pro Regular;
		font-size: 15px;
		margin-bottom: 15px;
		width: 79%;

		.bi-exclamation {
			color: red;
			font-size: 24px;
		}
	}
}

.delete-account-subtitle {
	@include mq($until: tablet) {
		display: flex;
		font-size: 15px;
		flex-direction: row;
		font-family: "DIN Next Pro Regular";
	}
	.bi-exclamation {
		color: red;
		font-size: 24px;
	}
}

.mobile-account {
	width: 100%;
	border: 1px solid rgb(222, 226, 230);
	border-radius: 0px;
}

.account-page {
	&-uninvite-member,
	&-invite-member {
		width: 100%;
		.input-group {
			width: 93%;
			margin: auto;

			&-text.icon {
				--bs-border-width: 0;
			}
		}

		.form-control.account-page-form {
			box-shadow: none !important;
			border: none !important;
			border-color: none;
		}
	}

	&-invite-member {
		button.btn-success {
			--bs-btn-padding-x: 1.65rem;
		}
	}
}

.marketingCheckbox {
	margin-left: -3.5em !important;
}
.modal-title {
	font-family: "DIN Next Pro Regular";
	font-style: normal;
	line-height: normal;
	font-size: 2.02rem;
}

.modal-body {
	font-family: "DIN Next Pro Regular";
	font-size: 1rem;
	font-style: normal;
	line-height: normal;
	text-decoration: none;
}
