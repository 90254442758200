// settings
@import "settings/_all.scss";

//Generic
@import "generic/_generic.reset.scss";
@import "generic/_generic.normalize.scss";
@import "generic/_generic.shared.scss";
@import "generic/_generic.helper.scss";

// tools
@import "tools/_all.scss";
//Transitions
@import "aos/src/sass/aos.scss";

//Components
@import "components/components.header.scss";
@import "components/components.footer.scss";
@import "components/components.longHeroComponent.scss";
@import "components/components.multiItemBannerComponent.scss";
@import "components/components.fiftyFiftyBillboard.scss";
@import "components/components.videoComponent.scss";
@import "components/components.bannerComponent.scss";
@import "components/components.textBannerComponent.scss";
@import "components/components.shortHeroComponent.scss";
@import "components/components.fiftyFiftyCarousel.scss";
@import "components/components.anchorComponent.scss";
@import "components/components.accordionComponent.scss";
@import "components/components.trustedByCarouselComponent.scss";
@import "components/components.singleHeadingComponent.scss";
@import "components/components.featureBillboardComponent.scss";
@import "components/components.billboardComponent.scss";
@import "components/components.featureComponent.scss";
@import "components/components.spacerComponent.scss";
@import "components/components.textComponent.scss";
@import "components/components.doubleParagraphComponent.scss";

//Pages
@import "components/components.productPage.scss";
@import "components/components.policyPage.scss";
@import "components/components.contactPage.scss";
@import "components/components.systemRequirements.scss";
@import "components/components.featurePage.scss";
@import "components/components.gallery.scss";
@import "components/components.versionBanner.scss";
@import "components/components.loginPage.scss";
@import "components/components.accountPage.scss";
@import "components/components.pricingPage.scss";
@import "components/components.errorPage.scss";
@import "components/components.thankYouPage.scss";
@import "components/components.newsArticles.scss";

//Elements
@import "elements/elements.footerColumns.scss";
@import "elements/elements.bannerItem.scss";
@import "elements/elements.billboardItem.scss";
@import "elements/elements.accordionItem.scss";
@import "elements/elements.systemRequirementsElement.scss";
@import "elements/elements.featureItem.scss";
@import "elements/elements.pricingItemElement.scss";

//gallery
@import "@splidejs/vue-splide/css";
@import "@splidejs/splide/css/skyblue";
@import "@splidejs/splide/css/sea-green";
@import "@splidejs/splide/css/core";

body {
	color: $color-dark-grey;
	font-family: "DIN Next Pro", fallback, "Roboto";
}

html {
	scroll-behavior: smooth;
}

.no-display {
	display: none;
}

.no-scroll {
	overflow: hidden;
}


.flex.flex-col.min-h-screen {
	z-index: 10;
	position: relative;
}

.toast-header {
	column-gap: 10px;
}


.grid-guidelines {
    column-gap: 30px;
    display: inline-grid;
	grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
    height: 10000%;
    pointer-events: none;
    position: absolute;
    width: 100vw;
    width: 100%;
    max-inline-size: -webkit-fill-available;
    align-items: start;
    z-index: 30;

	.item {
		border: 1px solid lightblue;
		background: lightblue;
		opacity: 0.2;
		height: 100%;
	}

	
	@media only screen and (min-width: 1920px) { 
		left: 0; 
		right: 0; 
		margin-left: auto; 
		margin-right: auto; 
		width: 1612px;
		column-gap: 28px;
	}


	@include mq($until:desktop){
		margin: 0 77px 0 77px;
		column-gap: 18px;
	}

	@include mq($until:tablet){
		grid-template-columns: auto auto auto auto;
		column-gap: 18px;
		margin: 0 25px 0 25px;
	}
}
