.cp-video {
    display: flex;
    padding: 2rem 2rem 7rem;
    width: 100%;
    align-items: center;
    justify-content: center; 

    video {
        width: 80%;
        height: 80%;
    }
}