.cp-text-banner {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;


    p {
        width: 45%;
        text-align: center;
    }
}