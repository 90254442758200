.splide__slide img {
	height: 100%;
  width: 100%;

  @include mq($until:tablet-wide){
    width: 30%;
    height: auto;
  }
  }



  .splide__slide {
    opacity: 0.3;
  }
  
  .splide__slide.is-active {
    opacity: 1;
  }

  .cp-fifty-fifty-carousel-images-container {
    .splide__track {
    overflow: visible!important;
  }
  }

  .splide__track--fade>.splide__list>.splide__slide {
    width: 100%!important;
    height: 360px!important;
  }

  .splide {
    padding:0!important;
  }

  .splide__arrow {

    @include mq($until:tablet-wide){
    width: 30%!important;
    }
  }

  .splide__arrow--next {
    justify-content: flex-end!important;

  }

  .splide__arrow--prev {
    justify-content: flex-start!important;
}

.splide__arrow:hover:not(:disabled) svg {
  fill:$color-orange;
}

.splide__arrow svg {
  fill:$color-orange!important;
  width: unset!important;
  height: unset!important;
}

.splide__track--nav>.splide__list>.splide__slide.is-active {
  border:3px solid $color-orange!important;
}

.splide__arrows.cp-version-banner-arrows.splide__arrows--ltr {
  bottom: -35px;
  position: absolute;
  width: 100%;
}