.page-404 {
    max-width: 1612px;
    margin: auto;
    padding: 97px;

    @include mq($until:tablet){
        padding: 40px;
    }

    &-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 20px;
    }
}