.cp-version-banner {
	align-items: stretch;
	display: flex;
	flex-direction: column;

	@include mq($until: desktop) {
		padding: 25px;
	}

	&-enterprise-btn {
		column-gap: 10px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;
		width: 100%;
		background: inherit;

		a {
			color: $color-black;
		}

		&:hover {
			a {
				color: $color-white;
			}
		}
	}

	&-container {
		width: 100%;
	}

	&-items {
		&.desktop {
			align-items: stretch;
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			margin: auto;
			max-width: 1612px;
			width: 88%;
			padding: 20px 0;

			@include mq($from: tablet-wide, $until: desktop) {
				width: 100%;
				height: 100%;
			}
			@include mq($until: tablet-wide) {
				display: none;
			}
		}
		&.mobile {
			display: none;
			@include mq($until: tablet-wide) {
				display: block;
			}
		}

		.splide__slide {
			opacity: 1 !important;
		}
	}

	&-item {
		align-items: center;
		background-color: $color-white;
		border-radius: 10px;
		-webkit-box-shadow: -1px -1px 11px 1px rgba(29, 29, 29, 0.19);
		-moz-box-shadow: -1px -1px 11px 1px rgba(29, 29, 29, 0.19);
		box-shadow: -1px -1px 11px 1px rgba(29, 29, 29, 0.19);
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		margin: 0 1rem;
		padding: 40px 37px 39px;
		transition: 0.5s;
		width: 345px;
		height: 465px;

		a {
			display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    row-gap: 10px;
		}

		&:nth-child(1) {
			border: 3px solid $color-orange;
		}

		@include mq($from: tablet, $until: tablet-wide) {
			width: unset;
		}

		@include mq($until: tablet) {
		    width: 100%;
			margin: 0;
		}

		&:hover {
			-webkit-box-shadow: -1px -1px 11px 4px rgba(29, 29, 29, 0.19);
			-moz-box-shadow: -1px -1px 11px 4px rgba(29, 29, 29, 0.19);
			box-shadow: -1px -1px 11px 4px rgba(29, 29, 29, 0.19);
			transition: 0.5s;

			a {
				color: $color-black;
			}
		}

		&-items {
			margin-bottom: 4%;
			color: $color-black;

			ul {
				width: 100%;
			}

			li {
				list-style: none;
				margin-left: 0.4rem;
				font-size: 15px;
			}
		}

		&-container {
			align-items: flex-end;
			column-gap: 3%;
			display: flex;
			flex-direction: row;

			&-img {
				width: 33%;
			}

			&-title {
				text-decoration: none !important;
				line-height: 1.6rem;
			}

			&-subtitle {
				color: #7f7f7f;
				font-size: 16px;
				margin-bottom: 7px;
				margin-top: 10px;

				&-bottom {
					font-size: 17px;
				}
			}
		}

		&-btn {
			align-self: start;
			background: inherit;
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 62%;
			justify-content: space-between;
			margin-top: 1rem;

			a {
				color: $color-black;
			}

			&:hover {
				a {
					color: $color-white;
				}
			}
		}
	}
}

hr.version-banner {
	width: 95%;
	margin-left: auto;
	margin-right: auto;
	padding: 0;
	height: 2px;
	border: 0;
	background: linear-gradient(0deg, rgba(245, 153, 67, 1) 1%, rgba(255, 102, 102, 1) 100%);
}
