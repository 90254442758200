.el-banner-item {
	align-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	

	img {
		margin: 8px 0px 20px;
		width: 80%;
	}

	&-title {
		margin-bottom: 10px;
		color: $color-dark-grey;
		font-family: "DIN Next Pro Light";
	}

	&-subtitle {
		color: $color-dark-grey;
		width: 110%;
		margin-top: 5px;
	}
    
	p {
		text-align: center;
	}
}

.el-product-banner-item {
    align-items: flex-start;
    background-color: $color-white;
    border-radius: 8px;
	box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
    height: 225px;
    justify-content: space-evenly;
    padding: 25px;
    width: 33%;
    row-gap: 10px;
	max-height: 225px;


	@include mq($from:tablet, $until:desktop){
		width: 63%;
		row-gap: 10px;
		justify-content: space-evenly;
	}


	@include mq($until:tablet){
		height: 200px;
		justify-content: center;
		max-height: 200px;
		padding: 25px;
		row-gap: 10px;
		width: 100%;
		row-gap: 5px;
	}

	img {
		margin: 8px 0px 20px;
		width: 80%;
	}

	&-title {
		color: $color-black;
		font-family: "DIN Next Pro Regular";
		font-size: 26px;
	}

	&-subtitle {
	    font-size: 16px!important;
		color: $color-black;
		line-height: 26px;
		width: 91%;
	}
}
