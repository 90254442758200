.cp-accordion {
	&-container {
		background: $color-orange-secondary;		
	}

	&-inner-container {
		align-items: center;
		display: flex;
		flex-direction: column;
		row-gap: 34px;
		padding: 7.2% 0;

		@include mq($until:tablet){
			padding: 10.2% 0;
		}
	}

	&-title, &-subtitle {
		color:$color-white;
	}

	&-title {
		@include mq($until:tablet){
			padding: 0 25px;
			text-align: center;
		}
	}

	.accordion {
		width: 100%;

		.accordion-item {
			text-align: left;
			background:$color-orange-secondary;
			border:none;
			box-shadow: none;
		}

		.accordion-header {
			border: none;
			color: $color-white;
			padding: 0px 208px;

			@include mq($until:tablet){
				padding: 0 25px;
			}
		}

		.accordion-body {
			row-gap: 20px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			background: $color-white;
		    padding: 40px 228px;

			.body-item * {
				font-family: "DIN Next Pro Regular";
			}


			@include mq($until:tablet){
				padding: 40px 25px;
			}
		}
	}

	&-bottom-text {
		margin-top: 70px;
		display: flex;
		flex-direction: column;
		row-gap: 10px;
		align-items: center;

		@include mq($until:tablet-wide){
			width: 80%;
			margin-top: 0px;
			text-align: center;
		}

		a,p {
			color:$color-white;
		}
	}
}


.accordion-button {
	align-items: baseline;
	
	@include mq($until:desktop){
		--bs-accordion-btn-padding-x: 0.25rem;
	}
}

.accordion-button.collapsed{
	color: $color-black;
	background-color: transparent;
}

.accordion-button:not(.collapsed) {
	color: $color-white;
	background-color: $color-orange-secondary;
	box-shadow:none;
}

.accordion-button:focus {
	border-color:none;
	box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
	background-image: url("/src/img/icons/up_arrow_white.svg");
	transform: none;
	
}
.accordion-button::after {
	background-image: url("/src/img/icons/down_arrow.svg");
	transform: none;
}

.accordion-active {
	background-color: $color-orange-secondary!important;
	color:$color-white!important;
}