$color-white: #ffff;
$color-black: black;
$color-dark-grey: #474747;
$color-darkest-grey: #1D1D1D;
$color-light-grey: #F0F0F0;
$color-lightest-grey: #D1D5DB;
$color-pink: #FF6666;
$color-orange: #EB8746;
$color-orange-secondary:#D3793F;
$color-blue: #60A5FA;


.orange {
    color:$color-orange;
}


.white {
	color: $color-white;
}

.dark-grey {
	color: $color-dark-grey;
}

.lightest-grey {
	color: $color-lightest-grey;
}

.light-grey {
    color:$color-light-grey;
}

.pink {
	color: $color-pink;
}
