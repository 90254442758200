.policy-page {
    &-container {
        padding: 0 7.8%;

        a {
            color: $color-orange;
            display: inline;

            &:hover {
                color:$color-orange-secondary;
            }
        }
    }
}