.cp-text {
    &-container {
        max-width: 1612px;
        margin: auto;
        width: 100%;
        padding: 50px 105px 70px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 10px;

        @include mq($until:desktop){
             padding: 45px 26px 55px;
        }
    }

    &-title {
        align-self: center;
    }

    &-title, &-subtitle {
        color:$color-black;
    }
} 